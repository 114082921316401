import { Icon, Text, Flex } from '@chakra-ui/react';
import { BiBox } from 'react-icons/bi';

interface Props {
    description: string;
}
export default function Empty({ description }: Props) {
    return (
        <Flex
            direction="column"
            alignItems="center"
            justifyContent="center"
            p={24}
        >
            <Icon as={BiBox} boxSize={12} opacity={.4} />
            <Text mt={2} fontSize="lg" opacity={.4}>
                {description}
            </Text>
        </Flex>
    );
}
