
import { Avatar, Button, Flex, IconButton, Popover, PopoverArrow, PopoverBody, PopoverContent, PopoverTrigger, Skeleton, SkeletonCircle, Stack, StackDivider, Text } from '@chakra-ui/react';
import { IPatient } from 'core/models/Patient';
import { formatDate } from 'core/utils';
import { FiFileText, FiEdit, FiMoreVertical } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';

interface Props {
    data: IPatient[];
    loading: boolean;
}
export default function ListView({ data, loading }: Props) {
    return (
        <Stack divider={<StackDivider />} spacing='4'>
            {
                (data.length === 0) ? <></> :
                    data.map((x, i) => (
                        <ListItem data={x} key={i} loading={loading} />
                    ))
            }
        </Stack>

    );
}
interface ItemProps {
    data: IPatient;
    loading: boolean;
}
const ListItem = ({ data, loading }: ItemProps) => {
    const { idpatient, firstname, lastname, last_modified, date_added } = data
    return (loading) ? (
        <Stack direction={'row'} spacing={4} align={'center'}>
            <SkeletonCircle size='8' />
            <Stack direction={'column'} spacing={2} fontSize={'sm'} w={'100%'}>
                <Skeleton height='10px' />
                <Skeleton height='10px' />
            </Stack>
        </Stack>
    ) : (
        <Stack direction={'row'} spacing={4} align={'center'} >

            <Avatar
                name={`${firstname} ${lastname}`}
                size={'sm'}
            />
            <Stack direction={'column'} spacing={0} fontSize={'sm'}>
                <Text textTransform={'uppercase'}>{firstname} {lastname}</Text>
                <Text color={'gray.500'}>Added {formatDate(date_added, 'll')} · Last updated {formatDate(last_modified, 'll')}</Text>
            </Stack>
            <MoreOptions id={idpatient} />
        </Stack>
    )
}

interface MoreProps {
    id: string;
}
const MoreOptions = ({ id }: MoreProps) => {
    const navigate = useNavigate();
    return (

        <Flex justifyContent="center" mt={4} style={{ marginLeft: 'auto' }}>
            <Popover placement="bottom-end" isLazy>
                <PopoverTrigger>
                    <IconButton
                        bg={'white'}
                        aria-label="More options"
                        icon={<FiMoreVertical />}
                        variant="solid"
                        w="fit-content"
                    />
                </PopoverTrigger>
                <PopoverContent w="fit-content" _focus={{ boxShadow: 'none' }}>
                    <PopoverArrow />
                    <PopoverBody>
                        <Stack divider={<StackDivider />}>
                            <Button
                                w="194px"
                                variant="ghost"
                                rightIcon={<FiFileText />}
                                justifyContent="space-between"
                                fontWeight="normal"
                                fontSize="sm"
                                onClick={() => navigate(`/record/${id}`)}
                            >
                                View record
                            </Button>
                            <Button
                                w="194px"
                                variant="ghost"
                                rightIcon={<FiEdit />}
                                justifyContent="space-between"
                                fontWeight="normal"
                                fontSize="sm"
                                onClick={() => navigate(`/patientform?id=${id}`)}
                            >
                                Edit profile
                            </Button>
                        </Stack>
                    </PopoverBody>
                </PopoverContent>
            </Popover>
        </Flex>
    );

}