import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody } from '@chakra-ui/react';
import { ReactNode } from 'react';
import { } from 'react-icons/fi';

interface Prop {
    show: boolean;
    onToggle: () => void;
    title: string;
    children: ReactNode;
    bgBlur?: boolean;
}
export default function ModalForm({ show, onToggle, title, children, bgBlur = true }: Prop) {

    return (
        <Modal onClose={onToggle} size={'lg'} isOpen={show}>
            <ModalOverlay
                bg='blackAlpha.300'
                backdropFilter={(bgBlur) ? 'blur(10px)' : ''}
            />
            <ModalContent>
                <ModalHeader>{title}</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    {children}
                </ModalBody>
            </ModalContent>
        </Modal>
    )
}