import { CodeResponse } from '@react-oauth/google';
import moment from 'moment';
import { config } from '.';

export const getGoogleTokens = () => {
    try {
        return JSON.parse(localStorage.getItem('gtokens') ?? '');
    } catch (error) {
        return {};
    }
};

export interface IGoogleEvent {
    id: string;
    summary: string;
    start: {
        dateTime: string;
        timeZone: 'Asia/Manila'
    };
    end: {
        dateTime: string;
        timeZone: 'Asia/Manila'
    };
    created?: string;
}

export interface ICreateEvent {
    summary: string;
    start: string;
    end: string
}

export const GoogleOAuth = async (data: CodeResponse) => {
    const ret = await fetch(
        `${config.url}/create-tokens`,
        {
            method: 'POST', // or 'PUT'
            headers: { ...config.headers },
            body: JSON.stringify(data),
        }
    )
        .then((response) => response.json())
        .then((data) => {
            return data;
        })
        .catch((error) => {
            return error;
        });

    return ret;
};

export const createCalendarEvent = async (param: ICreateEvent) => {
    const data = {
        action: 'insert',
        tokens: getGoogleTokens(),
        data: { ...param }
    }
    const ret = await fetch(
        `${config.url}/googleapi/calendar/event`,
        {
            method: 'POST', // or 'PUT'
            headers: { ...config.headers },
            body: JSON.stringify(data),
        }
    )
        .then((response) => response.json())
        .then((data) => {
            return data;
        })
        .catch((error) => {
            return error;
        });

    return ret;
};

export const deleteCalendarEvent = async (eventId: string) => {
    const data = {
        action: 'delete',
        tokens: getGoogleTokens(),
        data: {
            id: eventId
        }
    }
    const ret = await fetch(
        `${config.url}/googleapi/calendar/event`,
        {
            method: 'POST', // or 'PUT'
            headers: { ...config.headers },
            body: JSON.stringify(data),
        }
    )
        .then((response) => response.json())
        .then((data) => {
            return data;
        })
        .catch((error) => {
            return error;
        });

    return ret;
};

export const fetchCalendarListByRange = async (date: string, type: 'day' | 'week' | 'month') => {
    const data = {
        action: 'list',
        tokens: getGoogleTokens(),
        date: {
            start: moment(date).startOf(type).toISOString(true),
            end: moment(date).endOf(type).toISOString(true)
        }
    }
    const ret = await fetch(
        `${config.url}/googleapi/calendar/list`,
        {
            method: 'POST', // or 'PUT'
            headers: { ...config.headers },
            body: JSON.stringify(data),
        }
    )
        .then((response) => response.json())
        .then((data) => {
            return data;
        })
        .catch((error) => {
            return error;
        });

    return ret;
};