import { extendTheme } from "@chakra-ui/react";

const activeLabelStyles = {
  transform: "scale(0.85) translateY(-24px)"
};

const customTheme = extendTheme({
  fonts: {
    body: "Montserrat !important", // Set the custom font for the body text
  },
  components: {
    Heading: {
      baseStyle: {
        fontFamily: 'Montserrat',
        fontWeight: 400
      }
    },
    Button: {
      baseStyle: {
        fontFamily: 'Montserrat',
        fontWeight: 'normal'
      }
    },
    Text: {
      baseStyle: {
        fontFamily: 'Montserrat',
        fontWeight: 'normal'
      }
    },
    Input: {
      baseStyle: {
        fontFamily: 'Montserrat',
        fontWeight: 'normal'
      }
    },
    Textarea: {
      baseStyle: {
        fontFamily: 'Montserrat',
        fontWeight: 'normal',
      }
    },
    Select: {
      baseStyle: {
        fontFamily: 'Montserrat',
        fontWeight: 'normal',
      }
    },
    FormLabel: {
      baseStyle: {
        fontFamily: 'Montserrat',
        fontWeight: 'normal'
      }
    },
    Form: {
      variants: {
        floating: {
          container: {
            _focusWithin: {
              label: {
                ...activeLabelStyles
              }
            },
            "input:not(:placeholder-shown) + label, .chakra-select__wrapper + label, textarea:not(:placeholder-shown) ~ label": {
              ...activeLabelStyles
            },
            label: {
              top: 0,
              left: 0,
              zIndex: 2,
              position: "absolute",
              // color: "gray.500",
              backgroundColor: "white",
              pointerEvents: "none",
              mx: 3,
              px: 1,
              my: 2,
              transformOrigin: "left top"
            }
          }
        }
      }
    }
  }
});

export default customTheme;
