import { Box } from '@chakra-ui/react';
import Feature from 'components/Home/Feature';
import Footer from 'components/Home/Footer';
import Hero from 'components/Home/Hero';
import Navbar from 'components/Home/Navbar';
import ProblemAgitation from 'components/Home/ProblemAgitation';
import RequestAccount from 'components/Home/RequestAccount';
import { fetchPageToken } from 'core/models';
import Cookies from 'js-cookie';
import { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';

export default function Home() {
    const { ref, inView } = useInView({
        threshold: 0.2,
        triggerOnce: true,
    });

    useEffect(() => {
        if (inView) {
            fetchPageToken().then((data) => {
                Cookies.set('token', data.value, { expires: 0.5 });
            });
        }
    }, [inView]);

    return (
        <Navbar>
            <Hero />
            <Feature />
            <ProblemAgitation />
            <Box id="RequestAccount" ref={ref}><RequestAccount /></Box>
            <Footer />
        </Navbar>
    );
}
