import { Box, Flex, Heading, Spacer, Card, Text, Stat, StatLabel, StatNumber, Stack } from '@chakra-ui/react';
import TransactionsChart from 'components/Charts/TransactionsChart';
import PatientVisitsChart from 'components/Charts/PatientVisitsChart';
import DateRangePicker from 'components/DateRangePicker/DateRangePicker';
import { fetchOutstandingBalance, fetchPatientVisitsChart, fetchTreatmentChart, IResponseOustandingBalance, IResponsePatientVisitChart, IResponseTreatmentChart } from 'core/models/Report';
import { useState } from 'react';
import { useQueryClient, useMutation } from 'react-query';
import TablePatientsBalance from 'components/DataTable/TablePatientsBalance';

export default function Reports() {
    const queryClient = useQueryClient();
    const [patientVisit, setPatientVisit] = useState<IResponsePatientVisitChart[]>([]);
    const [treatments, setTreatments] = useState<IResponseTreatmentChart[]>([]);
    const [treatmentSum, setTreatmentSum] = useState<{ totalSum: number, paidSum: number, balance: number }>({ totalSum: 0, paidSum: 0, balance: 0 });
    const [patientBalance, setPatientBalance] = useState<IResponseOustandingBalance[]>([]);

    const fTreatments = useMutation(fetchTreatmentChart, {
        onSuccess: (data) => {
            setTreatments(data);
            setTreatmentSum(computeTreatments(data));
        },
        onError: () => {
            console.error('ERROR: API Failed');
        },
        onSettled: () => {
            queryClient.invalidateQueries('fetch');
        },
    });

    const fPatientVisit = useMutation(fetchPatientVisitsChart, {
        onSuccess: (data) => {
            setPatientVisit(data);
        },
        onError: () => {
            console.error('ERROR: API Failed');
        },
        onSettled: () => {
            queryClient.invalidateQueries('fetch');
        },
    });

    const fOustandingBalance = useMutation(fetchOutstandingBalance, {
        onSuccess: (data) => {
            setPatientBalance(data.filter((x: IResponseOustandingBalance) => x.balance > 0));
        },
        onError: () => {
            console.error('ERROR: API Failed');
        },
        onSettled: () => {
            queryClient.invalidateQueries('fetch');
        },
    });
    const selectDateRange = (startDate: string, endDate: string) => {
        fTreatments.mutate({ startDate, endDate });
        fPatientVisit.mutate({ startDate, endDate });
        fOustandingBalance.mutate({ startDate, endDate });
    }


    const computeTreatments = (data: IResponseTreatmentChart[]) => {
        let totalSum = 0;
        let paidSum = 0;

        for (const item of data) {
            totalSum += item.total;
            paidSum += item.paid;
        }

        const balance = totalSum - paidSum;
        return {
            totalSum,
            paidSum,
            balance
        };
    };
    return (
        <Box m={4}>
            <Flex minWidth='max-content' alignItems='center' gap='2' pb={4}>
                <Box>
                    <Heading size='md'>Reports</Heading>
                </Box>
                <Spacer />
                {/* <ButtonGroup gap='2'>
                    <Button colorScheme='blue'></Button>
                </ButtonGroup> */}
            </Flex>

            <Card p={4}>
                <DateRangePicker onChange={selectDateRange} />
            </Card>

            <Card p={4} mt={8} height={500} width={'100%'}>
                <Text fontSize='lg' mb={4}>Patient Visits</Text>
                <PatientVisitsChart data={patientVisit} />
            </Card>

            <Card p={4} mt={8} height={600} width={'100%'}>
                <Text fontSize='lg' mb={4}>Transactions</Text>
                <TransactionsChart data={treatments} />
            </Card>
            <Card p={4} mt={1}>
                <Stack spacing='24px' direction={{ base: 'column', md: 'row' }} >
                    <Stat>
                        <StatLabel>Total Amount</StatLabel>
                        <StatNumber>₱ {treatmentSum.totalSum.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</StatNumber>
                    </Stat>

                    <Stat>
                        <StatLabel>Total Amount Paid</StatLabel>
                        <StatNumber>₱ {treatmentSum.paidSum.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</StatNumber>
                    </Stat>
                    <Stat>
                        <StatLabel>Total Balance</StatLabel>
                        <StatNumber>₱ {treatmentSum.balance.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</StatNumber>
                    </Stat>
                </Stack>
            </Card>
            <Card p={4} mt={8}>
                <Text fontSize='lg' mb={4}>Patients with oustanding balance</Text>
                <TablePatientsBalance data={patientBalance} isLoading={fOustandingBalance.isLoading} />
            </Card>
        </Box>
    );
}
