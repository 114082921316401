import { ArrowBackIcon } from '@chakra-ui/icons';
import { Text, Button, Heading, Box, Flex, Container, VStack } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
export default function CardLoad() {
    const navigate = useNavigate();
    return (
        <Box bg={'white'}>
            <Box bg="blue.400" w="100%">
                <Container maxW='container.md'>
                    <Flex justify="space-between" align="center" h={20}>
                        <Heading as="h1" fontSize="2xl" color={'white'}>
                            Dentsys DB
                        </Heading>
                        {/* Add any other navbar elements here */}
                    </Flex>
                </Container>
            </Box>
            <Container maxW='container.md' mb={16}>
                <VStack
                    align='stretch'
                >
                    <Box pt={2}>
                        <Button leftIcon={<ArrowBackIcon />}
                            variant='ghost'
                            size='sm'
                            onClick={() => navigate('/')}
                        >
                            Back
                        </Button>
                    </Box>
                    <Box>
                        <Text fontSize='md' color={'blue.400'} as='b'>Current as of March 31, 2023</Text>

                    </Box>
                    <Box>
                        <Heading as='h3' size='lg'>
                            Privacy Policy
                        </Heading>
                    </Box>
                    <Box>
                        Privacy Policy for DentsysDB's Patient Information System, Records Management System, and Schedule Management System.
                        At Spooting, we take your privacy seriously. We are committed to protecting the personal information of our clients and their patients.<br /><br />
                        This Privacy Policy outlines the type of information we collect and how we use, store, and protect it.
                    </Box>
                    <br />
                    <Box>
                        <Heading as='h4' size='md'>
                            Information We Collect
                        </Heading>
                    </Box>
                    <Box>
                        We collect personal information such as patient names, addresses, phone numbers, and email addresses. We also collect medical records and treatment histories.
                    </Box>
                    <br />
                    <Box>
                        <Heading as='h4' size='md'>
                            How We Use Information
                        </Heading>
                    </Box>
                    <Box>
                        We use this information to provide our clients with the services they have requested. This includes managing patient records, scheduling appointments, and maintaining accurate medical records.
                        We may also use the information we collect to contact clients and their patients for follow-up appointments, reminders, or surveys. We will never sell or share this information with third-party companies.

                    </Box>
                    <br />
                    <Box>
                        <Heading as='h4' size='md'>
                            Data Security
                        </Heading>
                    </Box>
                    <Box>
                        We take data security seriously and use industry-standard security measures to protect personal information. This includes encryption, firewalls, and access controls to ensure data is protected from unauthorized access, disclosure, or alteration.
                        We also conduct regular audits and assessments of our systems and procedures to ensure they meet or exceed industry standards for data security.

                    </Box>
                    <br />
                    <Box>
                        <Heading as='h4' size='md'>
                            Third-Party Services
                        </Heading>

                    </Box>
                    <Box>
                        We use third-party services, such as Google Calendar API, to provide our clients with scheduling features. These services may collect information such as IP addresses and usage data. We ensure that any third-party service providers we use are GDPR compliant and meet our standards for data security.
                    </Box>
                    <br />
                    <Box>
                        <Heading as='h4' size='md'>
                            Updates to Privacy Policy
                        </Heading>

                    </Box>
                    <Box>
                        We reserve the right to update our Privacy Policy at any time. Clients will be notified of any changes via email or through our website.
                    </Box>
                    <br />
                    <Box>
                        <Heading as='h4' size='md'>
                            Contact Us
                        </Heading>

                    </Box>
                    <Box>
                        If you have any questions or concerns regarding our Privacy Policy, please contact us at sevillanthonyy@gmail.com.
                    </Box>
                </VStack>
            </Container>
        </Box>
    );
}
