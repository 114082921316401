import { Card, Input, InputGroup, InputLeftElement, useColorModeValue } from '@chakra-ui/react';
import { FiSearch } from 'react-icons/fi';

interface Props {
    onChange: (value: string) => void;
}
export default function SearchInput({ onChange }: Props) {
    return (
        <Card bg={useColorModeValue('white', 'gray.700')}>
            <InputGroup>
                <InputLeftElement children={<FiSearch />} />
                <Input type='text' placeholder='Lastname, firstname, or patiend id' onChange={(e) => onChange(e.target.value)} />
            </InputGroup>
        </Card>

    );
}
