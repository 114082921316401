import { FormControl, FormErrorMessage, FormLabel, Input, Box, VStack, Button, ButtonGroup, Spacer, Flex } from '@chakra-ui/react';
import { checkPatientExist, createPatient, IPatient } from 'core/models/Patient';
import { Message } from 'core/utils';
import { Field, Formik } from "formik";
import { debounce } from 'lodash';
import { useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import * as Yup from "yup";

interface Props {
    onToggle: () => void;
}
interface IDuplicate {
    lname: string;
    bdate: string;
}

export default function FormPatientInformation({ onToggle }: Props) {
    const queryClient = useQueryClient();
    const [checkDup, setCheckDup] = useState<IDuplicate>({ lname: '', bdate: '' })
    const { mutate, isLoading } = useMutation(createPatient, {
        onSuccess: (data) => {
            if (data.hasOwnProperty('message')) {
                Message('error', data.message);
                return
            }
            Message('success', 'Patient added');
            onToggle();
        },
        onError: () => {
            console.error('ERROR: API Failed');
        },
        onSettled: () => {
            queryClient.invalidateQueries('login');
        },
    });

    const onSubmit = async (values: any) => {
        let json: IPatient = {
            ...values,
            middlename: '',
            gender: '',
            address: '',
            email: '',
            status: '',
            occupation: '',
        }
        const check = await checkPatientExist(values.lastname, values.birthdate);
        if (check.exist) {
            Message('warning', `${values.lastname} with birthdate ${values.birthdate} already exist in the system.`, 8000)
            return
        }
        mutate(json);
    };

    const validatePatient = debounce(async (values: IDuplicate) => {
        setCheckDup(values);
        if (checkDup.bdate === '' && checkDup.lname === '') return;
        const check = await checkPatientExist(values.lname, values.bdate);
        if (check.exist === true) {
            Message('warning', `Patient already exist in the system.`)
        } else {
            Message('success', `The entered information has been verified, and no duplicate records were found in the system`, 8000)
        }
    }, 100);

    const initialValues = {
        firstname: "",
        lastname: "",
        contact: "",
        birthdate: "",
        idpatient: ""
    };
    const validationSchema = Yup.object({
        firstname: Yup.string().required(),
        lastname: Yup.string().required(),
        birthdate: Yup.string().required(),
        contact: Yup.string().required(),
        email: Yup.string().email(),
        // employedd: Yup.boolean().equals([true]),
    });
    return (
        <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={validationSchema}
        >
            {({ handleSubmit, errors, touched }) => (
                // <SimpleGrid columns={1} gap={6}>
                <VStack
                    spacing={4}
                    align='stretch'
                >
                    <Box >
                        <FormLabel htmlFor="firstname">First name</FormLabel>
                        <FormControl isInvalid={!!errors.firstname && touched.firstname}>
                            <Field
                                as={Input}
                                id="firstname"
                                name="firstname"
                                type="text"
                                variant="filled"
                            />
                            <FormErrorMessage>{errors.firstname}</FormErrorMessage>
                        </FormControl>
                    </Box>
                    <Box >
                        <FormLabel htmlFor="lastname">Last name</FormLabel>
                        <FormControl
                            isInvalid={!!errors.lastname && touched.lastname}
                            onBlur={(e: any) => validatePatient({ ...checkDup, lname: e.target.value })}
                        >
                            <Field
                                as={Input}
                                id="lastname"
                                name="lastname"
                                type="text"
                                variant="filled"
                            />
                            <FormErrorMessage>{errors.lastname}</FormErrorMessage>
                        </FormControl>
                    </Box>
                    <Box >
                        <FormLabel htmlFor="birthdate">Birthday</FormLabel>
                        <FormControl
                            isInvalid={!!errors.birthdate && touched.birthdate}
                            onBlur={(e: any) => validatePatient({ ...checkDup, bdate: e.target.value })}
                        >
                            <Field
                                as={Input}
                                id="birthdate"
                                name="birthdate"
                                type="date"
                                variant="filled"
                            />
                            <FormErrorMessage>{errors.birthdate}</FormErrorMessage>
                        </FormControl>
                    </Box>

                    <Box>
                        <FormLabel htmlFor="contact">Contact #</FormLabel>
                        <FormControl isInvalid={!!errors.contact && touched.contact}>
                            <Field
                                as={Input}
                                id="contact"
                                name="contact"
                                type="number"
                                variant="filled"
                            />
                            <FormErrorMessage>{errors.contact}</FormErrorMessage>
                        </FormControl>
                    </Box>
                    <Flex pb={4} pt={2}>
                        <Spacer />
                        <ButtonGroup spacing='6'>
                            <Button onClick={onToggle} isLoading={isLoading}>Cancel</Button>
                            <Button colorScheme='blue' onClick={() => handleSubmit()} isLoading={isLoading}>Save</Button>
                        </ButtonGroup>
                    </Flex>


                </VStack>
            )}
        </Formik>
    )
}