import { Box, Button, ButtonGroup, Flex, Heading, Spacer } from '@chakra-ui/react';
import MyCalendar, { IEvent } from 'components/Calendar/MyCalendar';
import { fetchCalendarListByRange, IGoogleEvent } from 'core/models/GoogleAPIs';
import { Message } from 'core/utils';
import { debounce } from 'lodash';
import moment from 'moment';
import { useState, useEffect } from 'react';
import { FcGoogle } from 'react-icons/fc';
import { useQuery } from 'react-query';

export const parseGoogleResponse = (response: IGoogleEvent[]): IEvent[] => {
    const events = response.map((value: IGoogleEvent) => {
        const startD = moment(value.start.dateTime);
        const endD = moment(value.end.dateTime);

        const event: IEvent = {
            id: value.id,
            title: value.summary,
            start: startD.toDate(),
            end: endD.toDate(),
        };

        return event;
    });

    return events;
};

export default function Calendar() {
    const [filter, setFilter] = useState<string>(moment().format('YYYY-MM-DD'));
    const { data, refetch, isFetching } = useQuery({
        queryKey: ['fetchCalendarListByRange', filter],
        queryFn: async () => {
            const res = await fetchCalendarListByRange(filter, 'week');
            if (![200, 204].includes(res.status)) {
                Message('error', res.statusText);
                return []
            }
            const parsed: IEvent[] = await parseGoogleResponse(res.data.items)
            return parsed
        }
    });
    useEffect(() => {
        refetch();
    }, [filter]);

    const onChangeFilter = debounce((date: string) => {
        setFilter(date);
    }, 500);
    return (
        <Box m={4}>
            <Flex minWidth='max-content' alignItems='center' gap='2' pb={4}>
                <Box>
                    <Heading size='md'>Google Calendar</Heading>
                </Box>
                <Spacer />
                <ButtonGroup gap='2'>
                    <Button colorScheme='blue' variant='ghost' leftIcon={<FcGoogle />}
                        isLoading={isFetching}
                        loadingText='Syncing data...'
                    >
                        Data is synced.
                    </Button>
                </ButtonGroup>
            </Flex>
            <MyCalendar onChange={onChangeFilter} data={(data !== undefined) ? data : []} refetch={() => refetch()} />
            {/* <CardWarning /> */}
        </Box>
    );
}
