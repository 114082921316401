import {
    Box,
    Flex,
    Stack,
    Heading
} from '@chakra-ui/react';
import FormRequestAccount from 'components/Form/FormRequestAccount';

const RequestAccount = () => {


    return (
        <Stack minH={'100vh'} direction={{ base: 'column', md: 'row' }} bg={'gray.800'} color={'white'}>
            <Flex p={8} flex={1} align={'center'} justify={'center'}>
                <Stack spacing={4} w={'full'} maxW={'lg'}>
                    <Box>
                        <Heading
                            mb={0}
                            fontSize={{ base: '3xl', md: '5xl' }}
                        >
                            Create an account
                        </Heading>
                        <p>Request for an account to be created to access the system</p>
                    </Box>
                    <Box>
                        <FormRequestAccount />
                    </Box>

                </Stack>
            </Flex>
        </Stack>
    )
};

export default RequestAccount;
