import {
    Box,
    Card,
    Flex,
    SimpleGrid,
    Stat,
    StatLabel,
    StatNumber,
    useColorModeValue,
} from '@chakra-ui/react';
import { ReactNode } from 'react';
// import { BsPerson } from 'react-icons/bs';
import { FiServer, FiUsers, FiUserMinus } from 'react-icons/fi';

interface Props {
    data: { all: number, active: number, inactive: number, storageSizeInGB: number }
}

export default function CardStats({ data }: Props) {
    return (
        <Box maxW="7xl" mx={'auto'} px={{ base: 2, sm: 12, md: 17 }}>
            {/* <chakra.h1
                textAlign={'center'}
                fontSize={'4xl'}
                py={10}
                fontWeight={'bold'}>
                Welcome to your App!
            </chakra.h1> */}
            <SimpleGrid columns={{ base: 1, lg: 3 }} spacing={{ base: 5, lg: 8 }}>
                {/* <StatsCard
                    title={'Within 3 months (Active)'}
                    stat={`${data.active}`}
                    icon={<BsPerson size={'3em'} />}
                /> */}
                <StatsCard
                    title={'Idle'}
                    stat={`${data.inactive}`}
                    icon={<FiUserMinus size={'3em'} />}
                />
                <StatsCard
                    title={'Total patients'}
                    stat={`${data.all}`}
                    icon={<FiUsers size={'3em'} />}
                />
                <StatsCard
                    title={'Storage (GB)'}
                    stat={`${data.storageSizeInGB}`}
                    icon={<FiServer size={'3em'} />}
                />
            </SimpleGrid>
        </Box>
    );
}

interface StatsCardProps {
    title: string;
    stat: string;
    icon: ReactNode;
}
function StatsCard(props: StatsCardProps) {
    const { title, stat, icon } = props;
    return (
        <Card>
            <Stat
                px={{ base: 2, md: 4 }}
                py={'5'}
                shadow={'xl'}

                borderColor={useColorModeValue('gray.800', 'gray.500')}
                rounded={'lg'}>
                <Flex justifyContent={'space-between'}>
                    <Box pl={{ base: 2, md: 4 }}>
                        <StatLabel fontFamily={'Montserrat'} fontWeight={'200'} isTruncated>
                            {title}
                        </StatLabel>
                        <StatNumber fontFamily={'Montserrat'} fontSize={'2xl'} fontWeight={'medium'}>
                            {stat}
                        </StatNumber>
                    </Box>
                    <Box
                        my={'auto'}
                        color={useColorModeValue('gray.800', 'gray.200')}
                        alignContent={'center'}>
                        {icon}
                    </Box>
                </Flex>
            </Stat>
        </Card>
    );
}
