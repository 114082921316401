import { Box, Card, CardBody, CardHeader, Flex, Heading, Spacer, Tooltip } from '@chakra-ui/react';
import FormPatientInformation from 'components/Form/FormPatientInformation';
import { fetchPatientInfo, IPatient } from 'core/models/Patient';
import { useEffect, useState } from 'react';
import { } from 'react-icons/fi';
import { useSearchParams } from 'react-router-dom';
import { formatDate } from 'core/utils';
import { InfoOutlineIcon } from '@chakra-ui/icons';

export default function PatientForm() {
    const [searchParams] = useSearchParams();
    const [action, setAction] = useState<'create' | 'update'>('create')
    const [patientInfo, setPatientInfo] = useState<IPatient>({
        firstname: "",
        middlename: "",
        lastname: "",
        gender: "",
        address: "",
        contact: "",
        email: "",
        status: "",
        birthdate: "",
        occupation: "",
        idpatient: "",
        clinic_id: ""
    });

    useEffect(() => {
        const id = searchParams.get('id');
        if (id === null || id === undefined) return;
        const fetch = async () => {
            const data = await fetchPatientInfo(id);
            data.birthdate = formatDate(data.birthdate, 'YYYY-MM-DD')
            console.log(data);
            setPatientInfo({ ...data });
        };
        fetch();
        setAction('update');
        return () => {
            // this now gets called when the component unmounts
        };
    }, [searchParams])

    return (
        <Box m={4}>
            <Flex minWidth='max-content' alignItems='center' gap='2' pb={4}>
                <Box>
                    <Heading size='md'>
                        Patient form

                    </Heading>
                </Box>
                <Spacer />
                {/* <ButtonGroup gap='2'>
                    <Button colorScheme='blue'>Actions</Button>
                </ButtonGroup> */}
            </Flex>


            <Card>
                <CardHeader>
                    <Heading size='sm'>
                        <Tooltip label='Form checks for duplicate for patients with the same lastname and birthdate' fontSize='md'>
                            <InfoOutlineIcon mr={2} />
                        </Tooltip>
                        Personal Information
                    </Heading>
                </CardHeader>
                <CardBody pt={0}>
                    <FormPatientInformation data={patientInfo} action={action} />
                </CardBody>

            </Card>

        </Box>
    );
}