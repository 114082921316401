import { useState } from 'react';
import PhotoAlbum, { RenderPhoto } from "react-photo-album";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import { Button, Card } from '@chakra-ui/react';
import { useQuery } from "react-query";
import { deleteS3BucketDataByFileName, fetchS3BucketDataById, IS3BucketImageData } from 'core/models/S3Bucket';
import { useParams } from 'react-router-dom';
import Empty from 'components/Empty/Empty';
import { Message } from 'core/utils';

const breakpoints = [4320, 2160, 1080, 640, 384, 256, 128];

interface ISlide {
    src: string;
    width: number;
    height: number;
    srcSet: { src: string; width: number; height: number; }[];
}

const PhotoGallery = () => {
    const { patientId } = useParams();
    const [index, setIndex] = useState(-1);
    const [slides, setSlides] = useState<ISlide[]>([]);
    const qS3BucketInfo = useQuery({
        queryKey: ['fetchS3BucketDataById', patientId],
        queryFn: async () => {
            const data = await fetchS3BucketDataById(patientId);
            if (data.message) {
                setSlides([]);
                return [];
            }
            const slides = await data.map((item: IS3BucketImageData) => {
                const width = (item.width ?? 0) * 1;
                const height = (item.height ?? 0) * 1;
                console.log({ width, height })
                return {
                    src: item.url,
                    width,
                    height,
                    srcSet: breakpoints.map((breakpoint) => {
                        const breakpointHeight = Math.round((height / width) * breakpoint);
                        return {
                            src: item.url,
                            width: breakpoint,
                            height: breakpointHeight,
                        };
                    }),
                };
            });
            setSlides(slides);
            return slides
        }
    });

    const renderPhoto: RenderPhoto<{ src: string; width: number; height: number; srcSet: { src: string; width: number; height: number; }[]; }>
        = ({ layoutOptions, imageProps: { alt, style, ...restImageProps } }) => {
            const fileName = restImageProps.src?.split("/").pop()?.split("?")[0] ?? '';
            const handleDelete = async (file: string) => {
                deleteS3BucketDataByFileName(file).then((data) => {
                    if (data.status === "success") {
                        qS3BucketInfo.refetch();
                        Message("success", "Image deleted successfully.");
                    } else {
                        Message("error", "An error occurred while deleting the image.");
                    }
                });
            }
            return (
                <div
                    style={{
                        alignItems: "center",
                        // width: style?.width,
                        width: (slides.length === 1) ? "324px" : style?.width,
                        padding: `${layoutOptions.padding - 2}px`,
                        paddingBottom: 0,
                    }}
                >
                    <img alt={alt} style={{ ...style, width: "100%", padding: 0 }} {...restImageProps} />
                    <Card
                        style={{
                            paddingTop: "8px",
                            paddingBottom: "8px",
                            overflow: "visible",
                            whiteSpace: "nowrap",
                            textAlign: "center",
                        }}
                    >
                        <Button colorScheme='gray' size='xs' mx={12} onClick={() => handleDelete(fileName)}>
                            Delete
                        </Button>
                    </Card>
                </div>
            );
        };
    return (
        <>
            {
                (qS3BucketInfo.data && Array.isArray(qS3BucketInfo.data) && qS3BucketInfo.data.length > 0) ?
                    <div style={{ display: "block", minHeight: "1px", width: "100%", overflow: "auto" }}>
                        <PhotoAlbum
                            layout="rows"
                            photos={slides.map((slide, index) => ({ ...slide, index, height: 1, width: 1}))}
                            onClick={({ index: current }) => setIndex(current)}
                            renderPhoto={renderPhoto}
                        />

                        <Lightbox
                            index={index}
                            slides={slides}
                            open={index >= 0}
                            close={() => setIndex(-1)}
                            plugins={[Zoom]}
                        />
                    </div>
                    :
                    <Empty description='No photos yet 🫣' />
            }

        </>

    );
};

export default PhotoGallery;