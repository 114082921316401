import {
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    useColorModeValue,
    Box,
    Link,
} from "@chakra-ui/react"
import Empty from "components/Empty/Empty";
import { IResponseOustandingBalance } from "core/models/Report";
import { useNavigate } from "react-router-dom";

interface Props {
    data: IResponseOustandingBalance[];
    isLoading: boolean;
}
export default function TablePatientsBalance({ data, isLoading }: Props) {
    return (
        <Box overflowX="auto">
            {
                (data.length > 0) ?
                    <Table variant="simple" w={'100%'}>
                        <Thead>
                            <Tr>
                                <Th w={'40%'} fontFamily={'Montserrat'}>Name</Th>
                                <Th w={'20%'} fontFamily={'Montserrat'}>Email</Th>
                                <Th w={'20%'} fontFamily={'Montserrat'}>Contact no.</Th>
                                <Th w={'20%'} fontFamily={'Montserrat'}>Balance</Th>
                            </Tr>
                        </Thead>

                        <Tbody>
                            {
                                (isLoading) ?
                                    <></>
                                    :
                                    // Array.from({ length: 30 }).map((col, i) => (
                                    data.map((x, i) => (
                                        <Row patient={x} />
                                    ))
                            }
                        </Tbody>


                    </Table>
                    :
                    <Empty description="No results found" />
            }
        </Box>
    );
}

interface RowProp {
    patient: IResponseOustandingBalance
}
const Row = ({ patient }: RowProp) => {
    const navigate = useNavigate();

    const handleClick = () => {
        navigate(`/record/${patient.idpatient}`);
    }

    return (
        <Tr _hover={{ bg: useColorModeValue('gray.100', 'gray.600') }}
            style={{ cursor: 'pointer' }}
            // onClick={handleClick}
            fontFamily={'Montserrat'}
        >
            <Td>
                <Link
                    color='blue.500'
                    href='#'
                    textTransform='uppercase'
                    onClick={handleClick}
                >
                    {patient.name}
                </Link>
            </Td>
            <Td>{patient.email}</Td>
            <Td>{patient.contact}</Td>
            <Td>{patient.balance}</Td>
        </Tr >
    )
}
