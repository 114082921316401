import { Box, Button, ButtonGroup, Flex, Heading, Spacer, SimpleGrid, Link, HStack, Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, CardBody, CardHeader, VStack, Select, FormLabel, Textarea, IconButton, Text, Popover, PopoverTrigger, PopoverContent, PopoverArrow, PopoverCloseButton, PopoverHeader, PopoverBody, Input, useClipboard } from '@chakra-ui/react';
import { FiEdit, FiPlus, FiMapPin, FiMail, FiPhone, FiShare } from 'react-icons/fi';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { addTreatmentRow, fetchRecord, fetchTreatments, IRecord, ITreatment, updateRecordAttribute } from 'core/models/Record';
import { useQuery } from "react-query";
import { fetchPatientInfo, IPatient, updatePatientLastmodified } from 'core/models/Patient';
import { formatDate } from 'core/utils';
import { debounce } from 'lodash';
import ListViewTreatments from 'components/ListView/ListViewTreatments';
import CardLoader from 'components/Card/CardLoader';
import { getUser } from 'core/models/Doctor';
import Empty from 'components/Empty/Empty';
import ButtonUpload from 'components/ButtonUpload/ButtonUpload';
import PhotoGallery from 'components/PhotoGallery/PhotoGallery';

export default function Record() {
    const isBasic = getUser().data.patientlimit === 100;
    const { patientId } = useParams();
    const clinicId = getUser().data.clinic_id ?? '';
    const clinicName = getUser().data.clinic_name ?? '';
    const navigate = useNavigate();
    const patientUrl = `http://${window.location.host}/share?key=${patientId}&value=${clinicId}&clinic=${clinicName}`
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [showPhotoGallery, setShowPhotoGallery] = useState<boolean>(false);
    const { onCopy, hasCopied } = useClipboard(patientUrl);
    const qPatientInfo = useQuery({
        queryKey: ['fetchPatientInfo', patientId],
        queryFn: async () => {
            const data = await fetchPatientInfo(patientId);
            setPatientInfo(data);
            return data
        }
    });
    const qRecord = useQuery({
        queryKey: ['fetchRecord', patientId],
        queryFn: async () => {
            const data = await fetchRecord(patientId);
            setRec({ ...data });
            return data
        }
    });
    const qTreatment = useQuery({
        queryKey: ['fetchTreatments', patientId],
        queryFn: async () => {
            const data = await fetchTreatments(patientId);
            if (data.hasOwnProperty('message')) return

            setTreatments(data);
            return data
        }
    });

    const [patientInfo, setPatientInfo] = useState<IPatient>({
        clinic_id: '',
        idpatient: '',
        firstname: '',
        lastname: '',
        middlename: '',
        gender: '',
        status: '',
        address: '',
        email: '',
        contact: '',
        birthdate: '',
        occupation: '',
        last_modified: ''
    })
    const [rec, setRec] = useState<IRecord>({
        idpatient: '',
        oral_hyg: '',
        tongue: '',
        lips: '',
        gingi: '',
        circ_deposit: '',
        muc_mem: '',
        notes: '',
        last_modified: ''
    })
    const [treatments, setTreatments] = useState<ITreatment[]>([]);

    const handleSelect = async (attribute: string, value: string) => {
        await updateRecordAttribute({
            idpatient: qPatientInfo.data.idpatient,
            attribute,
            value
        })
        setRec(prevState => ({
            ...prevState,
            [attribute]: value
        }));

        updatePatientLastmodified(patientId, qPatientInfo.data.last_modified);
    }

    const onChangeNotes = debounce(async (attribute: string, value: string) => {
        handleSelect(attribute, value);
        updatePatientLastmodified(patientId, qPatientInfo.data.last_modified);
    }, 500);

    const addRow = async () => {
        setIsSubmitting(true);
        await addTreatmentRow(qPatientInfo.data.idpatient);
        qTreatment.refetch();
        setIsSubmitting(false);
    }

    return (
        <Box m={4}>
            <Flex minWidth='max-content' alignItems='center' gap='2' pb={4}>
                <Box>
                    <Heading size='md'>Patient Record</Heading>
                </Box>
                <Spacer />
                <ButtonGroup gap='2'>
                    <Popover>
                        <PopoverTrigger>
                            <ButtonGroup gap='2'>
                                <Button colorScheme='blue' leftIcon={<FiShare />}>Share</Button>
                            </ButtonGroup>
                        </PopoverTrigger>
                        <PopoverContent>
                            <PopoverArrow />
                            <PopoverCloseButton />
                            <PopoverHeader>Share this URL</PopoverHeader>
                            <PopoverBody>
                                <Flex mb={2}>
                                    <Input
                                        disabled
                                        value={patientUrl}
                                        mr={2}
                                    />
                                    <Button onClick={onCopy}>{hasCopied ? "Copied!" : "Copy"}</Button>
                                </Flex>
                            </PopoverBody>
                        </PopoverContent>
                    </Popover>
                </ButtonGroup>
            </Flex>
            <VStack align='stretch' gap={4}>
                <CardLoader loading={qPatientInfo.isLoading}>
                    <Accordion defaultIndex={[0]} allowMultiple>
                        <AccordionItem>
                            <h2>
                                <AccordionButton>
                                    <Box as="span" flex='1' textAlign='left' pt={2} pb={2}>
                                        <Heading as='h4' size='sm'>
                                            Personal Information
                                        </Heading>
                                    </Box>
                                    <AccordionIcon />
                                </AccordionButton>
                            </h2>
                            <AccordionPanel pb={4}>
                                <SimpleGrid columns={[1, 1, 3]} spacing={4}>
                                    <Box>
                                        <Link onClick={() => navigate(`/patientform?id=${patientId}`)}>
                                            <HStack >
                                                <Heading size='md' textTransform='uppercase'>{patientInfo.firstname} {patientInfo.middlename} {patientInfo.lastname}</Heading>
                                                <FiEdit />
                                            </HStack>
                                        </Link>
                                        <VStack align={'left'} mt={4}>
                                            <HStack>
                                                <FiMapPin />
                                                <Text fontSize='sm'>
                                                    {patientInfo.address}
                                                </Text>
                                            </HStack>
                                            <HStack>
                                                <FiPhone />
                                                <Text fontSize='sm'>
                                                    {patientInfo.contact}
                                                </Text>
                                            </HStack>
                                            <HStack>
                                                <FiMail />
                                                <Text fontSize='sm'>
                                                    {patientInfo.email}
                                                </Text>
                                            </HStack>
                                        </VStack>
                                    </Box>
                                    <Box>
                                        <VStack align={'left'}>
                                            <Text fontSize='sm'>
                                                <Text as='samp'>Birthday:</Text> {formatDate(patientInfo.birthdate, 'll')}
                                            </Text>
                                            <Text fontSize='sm'>
                                                <Text as='samp'>Gender:</Text>  {patientInfo.gender}
                                            </Text>
                                            <Text fontSize='sm'>
                                                <Text as='samp'>Status:</Text> {patientInfo.status}
                                            </Text>
                                            <Text fontSize='sm'>
                                                <Text as='samp'>Occupation:</Text> {patientInfo.occupation}
                                            </Text>
                                        </VStack>
                                    </Box>
                                    <Box display={'inline-flex'}>
                                        <Text as='b' mr={4}>Last updated:</Text>
                                        <Text fontSize='md'>
                                            {formatDate(patientInfo.last_modified, 'll')}
                                        </Text>
                                    </Box>
                                </SimpleGrid>
                            </AccordionPanel>
                        </AccordionItem>
                    </Accordion>

                </CardLoader>

                <CardLoader loading={qRecord.isLoading}>
                    <CardHeader>
                        <Heading size='sm'>Dental Examination</Heading>
                    </CardHeader>
                    <CardBody pt={0}>
                        <SimpleGrid columns={[1, 1, 3]} spacing={4}>
                            <Box>
                                <FormLabel color={'gray.500'}>Oral Hygience</FormLabel>
                                <Select name='oral_hyg' onChange={(e) => handleSelect(e.target.name, e.target.value)} value={rec.oral_hyg}>
                                    <option value=''></option>
                                    <option value='Good'>Good</option>
                                    <option value='Fair'>Fair</option>
                                    <option value='Poor'>Poor</option>
                                </Select>
                            </Box>
                            <Box>
                                <FormLabel color={'gray.500'}>Tongue</FormLabel>
                                <Select name='tongue' onChange={(e) => handleSelect(e.target.name, e.target.value)} value={rec.tongue}>
                                    <option value=''></option>
                                    <option value='Normal'>Normal</option>
                                    <option value='Coated'>Coated</option>
                                </Select>
                            </Box>
                            <Box>
                                <FormLabel color={'gray.500'}>Lips</FormLabel>
                                <Select name='lips' onChange={(e) => handleSelect(e.target.name, e.target.value)} value={rec.lips}>
                                    <option value=''></option>
                                    <option value='Normal'>Normal</option>
                                    <option value='Herpes'>Herpes</option>
                                </Select>
                            </Box>
                            <Box>
                                <FormLabel color={'gray.500'}>Gingivae</FormLabel>
                                <Select name='gingi' onChange={(e) => handleSelect(e.target.name, e.target.value)} value={rec.gingi}>
                                    <option value=''></option>
                                    <option value='Normal'>Normal</option>
                                    <option value='Firm'>Firm</option>
                                    <option value='Receeded'>Receeded</option>
                                    <option value='Frequent Bleeding'>Frequent Bleeding</option>
                                </Select>
                            </Box>
                            <Box>
                                <FormLabel color={'gray.500'}>Circular Deposit</FormLabel>
                                <Select name='circ_deposit' onChange={(e) => handleSelect(e.target.name, e.target.value)} value={rec.circ_deposit}>
                                    <option value=''></option>
                                    <option value='Slight'>Slight</option>
                                    <option value='Moderate'>Moderate</option>
                                    <option value='Heavy'>Heavy</option>
                                </Select>
                            </Box>
                            <Box>
                                <FormLabel color={'gray.500'}>Mucous Membrane</FormLabel>
                                <Select name='muc_mem' onChange={(e) => handleSelect(e.target.name, e.target.value)} value={rec.muc_mem}>
                                    <option value=''></option>
                                    <option value='Normal'>Normal</option>
                                    <option value='Inflamed'>Inflamed</option>
                                </Select>
                            </Box>
                        </SimpleGrid>
                    </CardBody>
                </CardLoader>

                <CardLoader loading={qTreatment.isLoading}>
                    <CardHeader>
                        <Heading size='sm'>Treatments
                            <IconButton float={'right'} colorScheme={'blue'} aria-label='Add row'
                                icon={<FiPlus />}
                                onClick={addRow}
                                isLoading={isSubmitting}
                            />
                        </Heading>
                    </CardHeader>
                    <CardBody pt={0}>
                        {
                            (treatments.length > 0) ?
                                <ListViewTreatments data={treatments}
                                    refetch={() => qTreatment.refetch()}
                                    update={() => updatePatientLastmodified(patientId, qPatientInfo.data.last_modified)}
                                    share={false}
                                />
                                :
                                <Empty description='No treatments yet for this patient 🫣' />
                        }

                    </CardBody>
                </CardLoader>

                <CardLoader loading={qRecord.isLoading}>
                    <CardHeader>
                        <Heading size='sm'>Notes</Heading>
                    </CardHeader>
                    <CardBody pt={0}>
                        <Textarea placeholder='Other notes or remarks...' h={'240px'} name={'notes'} defaultValue={rec.notes} onChange={(e) => onChangeNotes(e.target.name, e.target.value)} />
                    </CardBody>
                </CardLoader>
                {
                    (isBasic) ? <></> :
                        <CardLoader loading={false}>
                            <CardHeader>
                                <Heading size='sm'>Saved photos
                                    <Flex float={'right'} gap={4}>
                                        <ButtonUpload onClick={() => setShowPhotoGallery(false)} />
                                    </Flex>
                                </Heading>
                            </CardHeader>
                            <CardBody pt={0}>
                                {
                                    (showPhotoGallery) ? <PhotoGallery /> :
                                        <Flex justifyContent="center" alignItems="center" style={{ height: '100%' }}>
                                            <Button onClick={() => setShowPhotoGallery(true)}>Load photos</Button>
                                        </Flex>
                                }
                            </CardBody>
                        </CardLoader>
                }

            </VStack>


        </Box>
    );
}
