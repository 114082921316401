import { createStandaloneToast } from "@chakra-ui/react";
const { toast } = createStandaloneToast()
var moment = require('moment'); // require

export const formatDate = (date: string = moment(), format: 'll' | 'lll' | 'YYYY-MM-DD' | 'LT') => {
    return moment(date).locale('en').format(format);
}

export const getDateToday = (format: 'll' | 'lll' | 'YYYY-MM-DD') => {
    return moment(new Date()).locale('en').format(format);
}

export enum StatusEnum {
    success = 'success',
    error = 'error',
    warning = 'warning',
    info = 'info'
}
export const Message = (status: 'success' | 'error' | 'warning' | 'info', text: string, duration: number = 3000) => {
    toast({
        description: text,
        status: status,
        duration: duration,
        isClosable: true,
        position: 'top'
    });
}

