import { formatDate } from "core/utils";
import { config, } from ".";
import { getUser } from "./Doctor";

export interface IPatient {
    idpatient: string;
    clinic_id: string;
    firstname: string;
    middlename: string;
    lastname: string;

    gender: string;
    address: string;
    contact: string;
    email: string;
    status: string;
    birthdate: string;
    occupation:string;

    last_modified?: string;
    date_added?: string;
}

export const createPatient = async (json: IPatient) => {
    json.clinic_id = getUser().data.clinic_id;
    let err = false
    const ret = await fetch(
        `${config.url}/patient/`,
        {
            method: 'POST', // or 'PUT'
            headers: { ...config.headers, 'clinic_id': getUser().data.clinic_id, 'Authorization': `Bearer ${getUser().access_token}` },
            body: JSON.stringify(json),
        }
    )
        .then((response) => response.json())
        .then((data) => {
            return data;
        })
        .catch((error) => {
            err = true;
            return error;
        });
    if (err === false) {
        await fetch(`${config.url}/record`, {
            method: 'POST', // or 'PUT'
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Authorization': `Bearer ${getUser().access_token}`
            },
            body: JSON.stringify(ret),
        }).catch((error) => {
            return error;
        });
    }
    return ret;
}

export const updatePatient = async (json: IPatient) => {
    json.clinic_id = getUser().data.clinic_id;
    const ret = await fetch(
        `${config.url}/patient/`,
        {
            method: 'PUT', // or 'PUT'
            headers: { ...config.headers, 'clinic_id': getUser().data.clinic_id, 'Authorization': `Bearer ${getUser().access_token}` },
            body: JSON.stringify(json),
        }
    )
        .then((response) => response.json())
        .then((data) => {
            return data;
        })
        .catch((error) => {
            return error;
        });

    return ret;
}

export const SearchPatients = async (param: string) => {
    const jsonBody = {
        searchString: param.trim(),
        limit: 80
    }
    const ret = await fetch(
        `${config.url}/patient/search`,
        {
            method: 'POST', // or 'PUT'
            headers: { ...config.headers, 'clinic_id': getUser().data.clinic_id, 'Authorization': `Bearer ${getUser().access_token}` },
            body: JSON.stringify(jsonBody),
        }
    )
        .then((response) => response.json())
        .then((data) => {
            return data;
        })
        .catch((error) => {
            return error;
        });

    return ret;
};

export const fetchPatientInfo = async (id: string = '', clinicId: string = getUser().data.clinic_id) => {
    const ret = await fetch(
        `${config.url}/patient/${id}`,
        {
            method: 'GET', // or 'PUT'
            headers: { ...config.headers, 'clinic_id': clinicId, 'Authorization': `Bearer ${getUser().access_token}` },
            // body: JSON.stringify(data),
        }
    )
        .then((response) => response.json())
        .then((data) => {
            return data;
        })
        .catch((error) => {
            return error;
        });

    return ret;
};

export const checkPatientExist = async (lastname: string, birthdate: string) => {
    const response = await fetch(`${config.url}/patient/action/existing?lastname=${lastname}&birthdate=${birthdate}`, {
        method: 'GET', // or 'PUT'
        headers: { ...config.headers, 'clinic_id': getUser().data.clinic_id, 'Authorization': `Bearer ${getUser().access_token}` },
    }).then(response => response.json())
        .then(data => {
            return data;
        })
        .catch((error) => {
            return error;
        });
    return response;
}

export const updatePatientLastmodified = async (idpatient: string = '0', last_modified: string) => {
    const date = formatDate(new Date().toDateString(), 'YYYY-MM-DD');
    const last_mod = formatDate(last_modified, "YYYY-MM-DD");
    if (date === last_mod) return;
    console.info('Update last modified date')
    const response = await fetch(`${config.url}/patient/action/updatedate?idpatient=${idpatient}&date=${date}`, {
        method: 'GET', // or 'PUT'
        headers: { ...config.headers, 'clinic_id': getUser().data.clinic_id, 'Authorization': `Bearer ${getUser().access_token}` },
    }).then(response => response.json())
        .then(data => {
            return data;
        })
        .catch((error) => {
            return error;
        });
    return response;
}

export const getPatientSummary = async () => {
    const ret = await Promise.all([
        fetch(`${config.url}/patient/action/count?param=all`, { method: 'GET', headers: { ...config.headers, 'clinic_id': getUser().data.clinic_id, 'Authorization': `Bearer ${getUser().access_token}` }, }),
        // fetch(`${config.url}/patient/action/count?param=active`, { method: 'GET', headers: { ...config.headers, 'clinic_id': getUser().data.clinic_id, 'Authorization': `Bearer ${getUser().access_token}` }, }),
        fetch(`${config.url}/patient/action/count?param=inactive`, { method: 'GET', headers: { ...config.headers, 'clinic_id': getUser().data.clinic_id, 'Authorization': `Bearer ${getUser().access_token}` }, }),
        fetch(`${config.url}/report/storageSum`, { method: 'GET', headers: { ...config.headers, 'clinic_id': getUser().data.clinic_id, 'Authorization': `Bearer ${getUser().access_token}` }, }),
        // fetch(`${config.url}/patient/action/recent?param=last_modified`, { method: 'GET', headers: { ...config.headers, 'clinic_id': getUser().data.clinic_id, 'Authorization': `Bearer ${getUser().access_token}` }, }),
        // fetch(`${config.url}/patient/action/recent?param=date_added`, { method: 'GET', headers: { ...config.headers, 'clinic_id': getUser().data.clinic_id, 'Authorization': `Bearer ${getUser().access_token}` }, })
    ]).then((values) => {
        let all = values[0].json();
        // let active = values[1].json();
        let inactive = values[1].json();
        let storageSizeInGB = values[2].json();
        return { all, inactive, storageSizeInGB }
        // return values
    }).catch((error) => {
        return error;
    });
    return ret
}

export const getAllPatientLastMod = async () => {
    const response = await fetch(`${config.url}/patient/action/recent?param=last_modified`, {
        method: 'GET', // or 'PUT'
        headers: { ...config.headers, 'clinic_id': getUser().data.clinic_id, 'Authorization': `Bearer ${getUser().access_token}` },
    }).then(response => response.json())
        .then(data => {
            return data;
        })
        .catch((error) => {
            return error;
        });
    return response;
}

export const getAllPatientDateAdded = async () => {
    const response = await fetch(`${config.url}/patient/action/recent?param=date_added`, {
        method: 'GET', // or 'PUT'
        headers: { ...config.headers, 'clinic_id': getUser().data.clinic_id, 'Authorization': `Bearer ${getUser().access_token}` },
    }).then(response => response.json())
        .then(data => {
            return data;
        })
        .catch((error) => {
            return error;
        });
    return response;
}

export const uploadImage = async (file: File | null, idPatient: string) => {
    if (file === null) {
        throw new Error("No file selected");
    }

    const formData = new FormData();
    formData.append('image', file);
    const ret = await fetch(
        `${config.url}/upload/${idPatient}`,
        {
            method: 'POST', // or 'PUT'
            // headers: { ...config.headers },
            body: formData,
        }
    )
        .then((response) => response.json())
        .then((data) => {
            return data;
        })
        .catch((error) => {
            return error;
        });

    return ret;
};