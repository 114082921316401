import { config } from '.';
import { googleLogout } from '@react-oauth/google';

export const authState = localStorage.getItem('user') ? true : false;

export const getUser = () => {
    try {
        return JSON.parse(localStorage.getItem('user') ?? '');
    } catch (error) {
        return {};
    }
};

export interface Account {
    clinic_id: string,
    username: string,
    // password?: string,
    name: string,
    patientlimit: number,
    clinic_name: string,
    clinic_address: string,
    clinic_contact: string,
    google_token?: string,
    email?: string,
    role?: string,
    last_login?: string,
}

export interface ILoginFormValues {
    username: string;
    password: string;
}

export const AuthLogin = async (data: ILoginFormValues) => {
    try {
        const username = data.username;
        const password = data.password;
        const authString = `${username}:${password}`;
        const encodedAuth = btoa(authString); // Base64 encode the username:password string

        const response = await fetch(`${config.url}/doctor/login`, {
            method: 'POST',
            headers: {
                ...config.headers,
                'Authorization': `${encodedAuth}`,
                'Content-Type': 'application/json',
            },
        });

        const ret = await response.json();
        return ret;
    } catch (error) {
        return error;
    }
};

export const VerifyEmail = async (email: string) => {
    const payload = {
        email: email
    }
    const ret = await fetch(
        `${config.url}/doctor/verify-email`,
        {
            method: 'POST', // or 'PUT'
            headers: { ...config.headers },
            body: JSON.stringify(payload),
        }
    )
        .then((response) => response.json())
        .then((data) => {
            return data;
        })
        .catch((error) => {
            return error;
        });

    return ret;
};

export const AuthLogout = async () => {
    localStorage.removeItem('user');
    localStorage.removeItem('gtokens');
    googleLogout();
};

export const updateGoogleToken = async (data: { username: string, google_token: string }) => {
    const ret = await fetch(
        `${config.url}/doctor/googletoken`,
        {
            method: 'PUT', // or 'PUT'
            headers: { ...config.headers },
            body: JSON.stringify(data),
        }
    )
        .then((response) => response.json())
        .then((data) => {
            return data;
        })
        .catch((error) => {
            return error;
        });

    return ret;
};