import {
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    TableCaption,
    useColorModeValue,
    Box,
    Link,
    Center,
    Spinner
} from "@chakra-ui/react"
import { IPatient } from "core/models/Patient";
import { formatDate } from "core/utils";
import { useNavigate } from "react-router-dom";

interface Props {
    data: IPatient[];
    isLoading: boolean;
}
export default function TablePatient({ data, isLoading }: Props) {
    return (
        <Box overflowX="auto">
            <Table variant="simple">
                <TableCaption fontFamily={'Montserrat'}>{
                    (isLoading) ?
                        <Center h='100%' color='white' p={32}>
                            <Spinner
                                thickness='4px'
                                speed='0.65s'
                                emptyColor='gray.200'
                                color='blue.500'
                                size={'lg'}
                            />
                        </Center>
                        :
                        `Search results: ${data.length}`
                }
                </TableCaption>
                <Thead>
                    <Tr>
                        <Th fontFamily={'Montserrat'}>ID</Th>
                        <Th w={'20%'} fontFamily={'Montserrat'}>Last updated</Th>
                        <Th w={'40%'} fontFamily={'Montserrat'}>Name</Th>
                        <Th w={'20%'} fontFamily={'Montserrat'}>Birthday</Th>
                        <Th w={'20%'} fontFamily={'Montserrat'}>Contact no.</Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {
                        (isLoading) ?
                            <></>
                            :
                            // Array.from({ length: 30 }).map((col, i) => (
                            data.map((x, i) => (
                                <Row patient={x} />
                            ))
                    }
                </Tbody>
            </Table>
        </Box>
    );
}

interface RowProp {
    patient: IPatient
}
const Row = ({ patient }: RowProp) => {
    const navigate = useNavigate();

    const handleClick = () => {
        navigate(`/record/${patient.idpatient}`);
    }

    return (
        <Tr _hover={{ bg: useColorModeValue('gray.100', 'gray.600') }}
            style={{ cursor: 'pointer' }}
            onClick={handleClick}
            fontFamily={'Montserrat'}
        >
            <Td>{patient.idpatient}</Td>
            <Td>{formatDate(patient.last_modified, 'll')}</Td>
            <Td>
                <Link
                    color='blue.500'
                    href='#'
                    textTransform='uppercase'
                >
                    {patient.lastname} {patient.firstname}
                </Link>
            </Td>
            <Td>{formatDate(patient.birthdate, 'll')}</Td>
            <Td>{patient.contact}</Td>
        </Tr >
    )
}
