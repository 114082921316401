import { Button, ButtonGroup, Flex, FormControl, FormErrorMessage, FormLabel, Grid, GridItem, Input, Select, Spacer } from '@chakra-ui/react';
import { checkPatientExist, createPatient, IPatient, updatePatient } from 'core/models/Patient';
import { Message } from 'core/utils';
import { Field, Formik } from "formik";
import { debounce } from 'lodash';
import { useState } from 'react';
import { useQueryClient, useMutation } from 'react-query';
import * as Yup from "yup";

interface Props {
    data: IPatient;
    action: 'create' | 'update';
}

interface IDuplicate {
    lname: string;
    bdate: string;
}
export default function FormPatientInformation({ data, action }: Props) {
    const queryClient = useQueryClient();
    const [checkDup, setCheckDup] = useState<IDuplicate>({ lname: '', bdate: '' })
    const { mutate, isLoading } = useMutation(((action === 'create') ? createPatient : updatePatient), {
        onSuccess: (data) => {
            if (data.hasOwnProperty('message')) {
                Message('error', data.message);
                return
            }
            const mssg = (action === 'create') ? 'Patient added' : 'Patient updated'
            Message('success', mssg);
        },
        onError: () => {
            console.error('ERROR: API Failed');
        },
        onSettled: () => {
            queryClient.invalidateQueries('login');
        },
    });
    const onSubmit = async (values: IPatient) => {
        const check = await checkPatientExist(values.lastname, values.birthdate);
        if (check.exist === true && action === 'create') {
            Message('warning', `${values.lastname} with birthdate ${values.birthdate} already exist in the system.`, 8000)
            return
        }
        mutate(values);
    };

    const validatePatient = debounce(async (values: IDuplicate) => {
        setCheckDup(values);
        if (checkDup.bdate === '' && checkDup.lname === '') return;
        const check = await checkPatientExist(values.lname, values.bdate);
        if (check.exist === true && action === 'create') {
            Message('warning', `Patient already exist in the system.`)
        } else {
            Message('success', `The entered information has been verified, and no duplicate records were found in the system`, 8000)
        }
    }, 100);

    const validationSchema = Yup.object({
        firstname: Yup.string().required(),
        lastname: Yup.string().required(),
        birthdate: Yup.string().required(),
        age: Yup.number().min(2),
        contact: Yup.string().required(),
        email: Yup.string().email(),
    });
    return (
        <Formik
            enableReinitialize={true}
            initialValues={data}
            onSubmit={onSubmit}
            validationSchema={validationSchema}
        >
            {({ handleSubmit, errors, touched, isSubmitting, resetForm }) => (
                <>
                    <Grid templateColumns='repeat(3, 1fr)' gap={6}>
                        <GridItem colSpan={[3, 1, 1]}>
                            <FormLabel htmlFor="firstname">First name</FormLabel>
                            <FormControl isInvalid={!!errors.firstname && touched.firstname}>
                                <Field
                                    as={Input}
                                    id="firstname"
                                    name="firstname"
                                    type="text"
                                    variant="filled"
                                />
                                <FormErrorMessage>{errors.firstname}</FormErrorMessage>
                            </FormControl>
                        </GridItem>
                        <GridItem colSpan={[3, 1, 1]}>
                            <FormLabel htmlFor="middlename">Middle name</FormLabel>
                            <FormControl isInvalid={!!errors.middlename && touched.middlename}>
                                <Field
                                    as={Input}
                                    id="middlename"
                                    name="middlename"
                                    type="text"
                                    variant="filled"
                                />
                                <FormErrorMessage>{errors.middlename}</FormErrorMessage>
                            </FormControl>
                        </GridItem>
                        <GridItem colSpan={[3, 1, 1]}>
                            <FormLabel htmlFor="lastname">Last name</FormLabel>
                            <FormControl
                                isInvalid={!!errors.lastname && touched.lastname}
                                onBlur={(e: any) => validatePatient({ ...checkDup, lname: e.target.value })}
                            >
                                <Field
                                    as={Input}
                                    id="lastname"
                                    name="lastname"
                                    type="text"
                                    variant="filled"
                                />
                                <FormErrorMessage>{errors.lastname}</FormErrorMessage>
                            </FormControl>
                        </GridItem>
                        <GridItem colSpan={[3, 1, 1]}>
                            <FormLabel htmlFor="birthdate">Birthday</FormLabel>
                            <FormControl
                                isInvalid={!!errors.birthdate && touched.birthdate}
                                onBlur={(e: any) => validatePatient({ ...checkDup, bdate: e.target.value })}
                            >
                                <Field
                                    as={Input}
                                    id="birthdate"
                                    name="birthdate"
                                    type="date"
                                    variant="filled"
                                />
                                <FormErrorMessage>{errors.birthdate}</FormErrorMessage>
                            </FormControl>
                        </GridItem>
                        <GridItem colSpan={[3, 1, 1]}>
                            <FormLabel htmlFor="gender">Gender</FormLabel>
                            <FormControl isInvalid={!!errors.gender && touched.gender}>
                                <Field
                                    as={Select}
                                    id="gender"
                                    name="gender"
                                    type="date"
                                    variant="filled"
                                >
                                    <option value=""></option>
                                    <option value="Male">Male</option>
                                    <option value="Female">Female</option>
                                </Field>
                                <FormErrorMessage>{errors.gender}</FormErrorMessage>
                            </FormControl>
                        </GridItem>
                        <GridItem colSpan={[3, 1, 1]}>
                            <FormLabel htmlFor="status">Status</FormLabel>
                            <FormControl isInvalid={!!errors.status && touched.status}>
                                <Field
                                    as={Select}
                                    id="status"
                                    name="status"
                                    type="date"
                                    variant="filled"
                                >
                                    <option value=""></option>
                                    <option value="Single">Single</option>
                                    <option value="Married">Married</option>
                                    <option value="Widowed">Widowed</option>
                                </Field>
                                <FormErrorMessage>{errors.status}</FormErrorMessage>
                            </FormControl>
                        </GridItem>

                        <GridItem colSpan={3}>
                            <FormLabel htmlFor="occupation">Occupation</FormLabel>
                            <FormControl isInvalid={!!errors.occupation && touched.occupation}>
                                <Field
                                    as={Input}
                                    id="occupation"
                                    name="occupation"
                                    type="string"
                                    variant="filled"
                                />
                                <FormErrorMessage>{errors.occupation}</FormErrorMessage>
                            </FormControl>
                        </GridItem>

                        <GridItem colSpan={3}>
                            <FormLabel htmlFor="contact">Contact #</FormLabel>
                            <FormControl isInvalid={!!errors.contact && touched.contact}>
                                <Field
                                    as={Input}
                                    id="contact"
                                    name="contact"
                                    type="number"
                                    variant="filled"
                                />
                                <FormErrorMessage>{errors.contact}</FormErrorMessage>
                            </FormControl>
                        </GridItem>
                        <GridItem colSpan={3}>
                            <FormLabel htmlFor="email">Email</FormLabel>
                            <FormControl isInvalid={!!errors.email && touched.email}>
                                <Field
                                    as={Input}
                                    id="email"
                                    name="email"
                                    type="email"
                                    variant="filled"
                                />
                                <FormErrorMessage>{errors.email}</FormErrorMessage>
                            </FormControl>
                        </GridItem>
                        <GridItem colSpan={3}>
                            <FormLabel htmlFor="address">Address</FormLabel>
                            <FormControl isInvalid={!!errors.address && touched.address}>
                                <Field
                                    as={Input}
                                    id="address"
                                    name="address"
                                    type="text"
                                    variant="filled"
                                />
                                <FormErrorMessage>{errors.address}</FormErrorMessage>
                            </FormControl>
                        </GridItem>

                    </Grid>
                    <Flex pt={8}>
                        <Spacer />
                        <ButtonGroup spacing='4'>
                            <Button isLoading={isLoading} isDisabled={(action === "update")} onClick={() => resetForm()}>Clear</Button>
                            <Button colorScheme='blue'
                                isLoading={isLoading}
                                onClick={() => handleSubmit()}>{(action === "create") ? 'Save' : 'Update'}
                            </Button>
                        </ButtonGroup>
                    </Flex>
                </>

            )}
        </Formik>
    );
}
