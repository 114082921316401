import {
    Box,
    Flex,
    Heading,
    List,
    ListIcon,
    ListItem
} from '@chakra-ui/react';
import { MdCheckCircle } from 'react-icons/md';
import { FaTimesCircle } from "react-icons/fa";


const ProblemAgitation = () => {
    const consList = ['Time-consuming and prone to errors', 'Not easily accessible', 'Document are vulnerable to loss, or damage', 'Document require storage space and maintenance'];
    const prosList = ['Improved productivity', 'Access anywhere', 'Reduce the risk of document loss', 'Eliminate paper storage needs'];

    return (
        <Flex minH={'100vh'} p={8} flex={1} align={'center'} justify={'center'}>
            <Box p={8} w={1200}>
                {/* Heading Row */}
                <Heading as="h2" mb={12} fontWeight="bold" textAlign={'center'}>
                    Tired of wasting time and space?
                </Heading>

                {/* Two Column Row */}
                <Flex flexWrap="wrap" justifyContent="center" gap={1}>
                    {/* Column 1 for Cons */}
                    <Box flex={1} bg={'red.100'} py={8} px={12} mb={{base: 2, md: 8}} borderRadius={16}>
                        <Heading as="h5" mb={2}>
                            Traditional method
                        </Heading>
                        <List spacing={3}>
                            {consList.map((item, index) => (
                                <ListItem key={index}>
                                    <ListIcon as={FaTimesCircle} color='red.500' />
                                    {item}
                                </ListItem>
                            ))}
                        </List>
                    </Box>

                    {/* Column 2 for Pros */}
                    <Box flex={1} bg={'green.100'} py={8} px={12} mb={{base: 2, md: 8}} borderRadius={16}>
                        <Heading as="h5" mb={2}>
                            With cloud-solution
                        </Heading>
                        <List spacing={3}>
                            {prosList.map((item, index) => (
                                <ListItem key={index}>
                                    <ListIcon as={MdCheckCircle} color='green.500' />
                                    {item}
                                </ListItem>
                            ))}
                        </List>
                    </Box>
                </Flex>
            </Box>
        </Flex>
    );
};

export default ProblemAgitation;
