import { ReactNode } from 'react';
import {
    Stack,
    Container,
    Box,
    Text,
    Heading,
    SimpleGrid,
} from '@chakra-ui/react';

export default function StatsGridWithImage() {
    return (
        <Box bg={'gray.800'} position={'relative'}>
            <Container maxW={'7xl'} zIndex={10} position={'relative'}>
                <Stack direction={{ base: 'column', lg: 'row' }}>
                    <Stack
                        flex={1}
                        color={'gray.400'}
                        justify={{ lg: 'center' }}
                        py={{ base: 4, md: 20, xl: 60 }}>
                        <Box mb={{ base: 8, md: 20 }}>
                            <Text
                                fontFamily={'heading'}
                                fontWeight={700}
                                textTransform={'uppercase'}
                                mb={3}
                                fontSize={'xl'}
                                color={'gray.500'}>
                                Features
                            </Text>
                            <Heading
                                color={'white'}
                                mb={5}
                                fontSize={{ base: '3xl', md: '5xl' }}>
                                Dentsys DB
                            </Heading>
                            <Text fontSize={'xl'} color={'gray.400'}>
                                A cloud-based solution for dental clinics to streamline their operations and provide efficient services to their patients. It will serve as a Patient Information System, and Records Management System for dental clinics. It will allow doctors to manage patient information, and create/update/track patient procedures.
                            </Text>
                        </Box>

                        <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10}>
                            {stats.map((stat) => (
                                <Box key={stat.title}>
                                    <Text
                                        fontFamily={'heading'}
                                        fontSize={'3xl'}
                                        color={'white'}
                                        mb={3}>
                                        {stat.title}
                                    </Text>
                                    <Text fontSize={'xl'} color={'gray.400'}>
                                        {stat.content}
                                    </Text>
                                </Box>
                            ))}
                        </SimpleGrid>
                    </Stack>
                </Stack>
            </Container>
        </Box>
    );
}

const StatsText = ({ children }: { children: ReactNode }) => (
    <Text as={'span'} fontWeight={700} color={'white'}>
        {children}
    </Text>
);

const stats = [
    {
        title: 'Patient List',
        content: (
            <>
                <StatsText>Access detailed patient records</StatsText> individually or retrieve a complete patient listing with the flexibility to view them online, anytime, and from anywhere.
            </>
        ),
    },
    {
        title: 'Treatment Records',
        content: (
            <>
                <StatsText>Update and track</StatsText> every procedure done on every individual consultations. <StatsText>Shareable</StatsText> with other doctors for second opinion.
            </>
        ),
    },
    {
        title: 'Image Storage',
        content: (
            <>
                Integrated AWS S3 to enable doctors <StatsText>to upload images</StatsText> for specific patients.
            </>
        ),
    },
    {
        title: 'Clinic Reports',
        content: (
            <>
                Get a <StatsText>summary of all the activities</StatsText>, including patients with outstanding balance to your clinic.
            </>
        ),
    },
    {
        title: 'Cross-Platform Compatibility',
        content: (
            <>
                The app is <StatsText>compatible with various operating systems and platforms</StatsText>, including iOS, Android, Windows, macOS, and web browsers. Users can <StatsText>seamlessly switch between devices</StatsText> while maintaining a consistent experience.
            </>
        ),
    },
];