import {
    Box, Flex, Heading, SimpleGrid, Link, HStack,
    Accordion, AccordionButton, AccordionIcon, AccordionItem,
    AccordionPanel, CardBody, CardHeader, VStack,
    Select, FormLabel, Textarea, Text
} from '@chakra-ui/react';
import { FiEdit, FiMapPin, FiMail, FiPhone } from 'react-icons/fi';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { fetchRecord, fetchTreatments, IRecord, ITreatment } from 'core/models/Record';
import { useQuery } from "react-query";
import { fetchPatientInfo, IPatient } from 'core/models/Patient';
import { formatDate } from 'core/utils';
import ListViewTreatments from 'components/ListView/ListViewTreatments';
import CardLoader from 'components/Card/CardLoader';
import Empty from 'components/Empty/Empty';


export default function RecordShare() {
    const queryParams = new URLSearchParams(window.location.search)
    const patientId = queryParams.get("key") ?? '';
    const clinicId = queryParams.get("value") ?? '';
    const clinicName = queryParams.get("clinic") ?? '';
    const navigate = useNavigate();

    const qPatientInfo = useQuery({
        queryKey: ['fetchPatientInfo', patientId],
        queryFn: async () => {
            const data = await fetchPatientInfo(patientId, clinicId);
            setPatientInfo(data);
            return data
        }
    });
    const qRecord = useQuery({
        queryKey: ['fetchRecord', patientId],
        queryFn: async () => {
            const data = await fetchRecord(patientId, clinicId);
            setRec({ ...data });
            return data
        }
    });
    const qTreatment = useQuery({
        queryKey: ['fetchTreatments', patientId],
        queryFn: async () => {
            const data = await fetchTreatments(patientId, clinicId);
            if (data.hasOwnProperty('message')) return

            setTreatments(data);
            return data
        }
    });
    const [patientInfo, setPatientInfo] = useState<IPatient>({
        clinic_id: '',
        idpatient: '',
        firstname: '',
        lastname: '',
        middlename: '',
        gender: '',
        status: '',
        address: '',
        email: '',
        contact: '',
        birthdate: '',
        occupation: '',
        last_modified: ''
    })
    const [rec, setRec] = useState<IRecord>({
        idpatient: '',
        oral_hyg: '',
        tongue: '',
        lips: '',
        gingi: '',
        circ_deposit: '',
        muc_mem: '',
        notes: '',
        last_modified: ''
    })
    const [treatments, setTreatments] = useState<ITreatment[]>([]);

    return ( //margin: '2rem 16rem',
        <Box m={4} style={{ pointerEvents: 'none' }}
            mt={'2rem'} mb={'2rem'}
            mr={['2rem', '2rem', '8rem', '16rem']}
            ml={['2rem', '2rem', '8rem', '16rem']}
        >
            <Flex minWidth='max-content' alignItems='center' gap='2' pb={4}>
                <Box>
                    <Heading size='lg'>{clinicName}</Heading>
                </Box>
            </Flex>
            <VStack align='stretch' gap={4}>
                <CardLoader loading={qPatientInfo.isLoading}>
                    <Accordion defaultIndex={[0]} allowMultiple>
                        <AccordionItem>
                            <h2>
                                <AccordionButton>
                                    <Box as="span" flex='1' textAlign='left' pt={2} pb={2}>
                                        <Heading as='h4' size='sm'>
                                            Personal Information
                                        </Heading>
                                    </Box>
                                    <AccordionIcon />
                                </AccordionButton>
                            </h2>
                            <AccordionPanel pb={4}>
                                <SimpleGrid columns={[1, 1, 3]} spacing={4}>
                                    <Box>
                                        <Link onClick={() => navigate(`/patientform?id=${patientId}`)}>
                                            <HStack >
                                                <Heading size='md' textTransform='uppercase'>{patientInfo.firstname} {patientInfo.middlename} {patientInfo.lastname}</Heading>
                                                <FiEdit />
                                            </HStack>
                                        </Link>
                                        <VStack align={'left'} mt={4}>
                                            <HStack>
                                                <FiMapPin />
                                                <Text fontSize='sm'>
                                                    {patientInfo.address}
                                                </Text>
                                            </HStack>
                                            <HStack>
                                                <FiPhone />
                                                <Text fontSize='sm'>
                                                    {patientInfo.contact}
                                                </Text>
                                            </HStack>
                                            <HStack>
                                                <FiMail />
                                                <Text fontSize='sm'>
                                                    {patientInfo.email}
                                                </Text>
                                            </HStack>
                                        </VStack>
                                    </Box>
                                    <Box>
                                        <VStack align={'left'}>
                                            <Text fontSize='sm'>
                                                <Text as='samp'>Birthday:</Text> {formatDate(patientInfo.birthdate, 'll')}
                                            </Text>
                                            <Text fontSize='sm'>
                                                <Text as='samp'>Gender:</Text>  {patientInfo.gender}
                                            </Text>
                                            <Text fontSize='sm'>
                                                <Text as='samp'>Status:</Text> {patientInfo.status}
                                            </Text>
                                            <Text fontSize='sm'>
                                                <Text as='samp'>Occupation:</Text> {patientInfo.occupation}
                                            </Text>
                                        </VStack>
                                    </Box>
                                    <Box display={'inline-flex'}>
                                        <Text as='b' mr={4}>Last updated:</Text>
                                        <Text fontSize='md'>
                                            {formatDate(patientInfo.last_modified, 'll')}
                                        </Text>
                                    </Box>
                                </SimpleGrid>
                            </AccordionPanel>
                        </AccordionItem>
                    </Accordion>

                </CardLoader>

                <CardLoader loading={qRecord.isLoading}>
                    <CardHeader>
                        <Heading size='sm'>Dental Examination</Heading>
                    </CardHeader>
                    <CardBody pt={0}>
                        <SimpleGrid columns={[1, 1, 3]} spacing={4}>
                            <Box>
                                <FormLabel color={'gray.500'}>Oral Hygience</FormLabel>
                                <Select name='oral_hyg'>
                                    <option value=''></option>
                                    <option value='Good'>Good</option>
                                    <option value='Fair'>Fair</option>
                                    <option value='Poor'>Poor</option>
                                </Select>
                            </Box>
                            <Box>
                                <FormLabel color={'gray.500'}>Tongue</FormLabel>
                                <Select name='tongue'>
                                    <option value=''></option>
                                    <option value='Normal'>Normal</option>
                                    <option value='Coated'>Coated</option>
                                </Select>
                            </Box>
                            <Box>
                                <FormLabel color={'gray.500'}>Lips</FormLabel>
                                <Select name='lips'>
                                    <option value=''></option>
                                    <option value='Normal'>Normal</option>
                                    <option value='Herpes'>Herpes</option>
                                </Select>
                            </Box>
                            <Box>
                                <FormLabel color={'gray.500'}>Gingivae</FormLabel>
                                <Select name='gingi'>
                                    <option value=''></option>
                                    <option value='Normal'>Normal</option>
                                    <option value='Firm'>Firm</option>
                                    <option value='Receeded'>Receeded</option>
                                    <option value='Frequent Bleeding'>Frequent Bleeding</option>
                                </Select>
                            </Box>
                            <Box>
                                <FormLabel color={'gray.500'}>Circular Deposit</FormLabel>
                                <Select name='circ_deposit'>
                                    <option value=''></option>
                                    <option value='Slight'>Slight</option>
                                    <option value='Moderate'>Moderate</option>
                                    <option value='Heavy'>Heavy</option>
                                </Select>
                            </Box>
                            <Box>
                                <FormLabel color={'gray.500'}>Mucous Membrane</FormLabel>
                                <Select name='muc_mem'>
                                    <option value=''></option>
                                    <option value='Normal'>Normal</option>
                                    <option value='Inflamed'>Inflamed</option>
                                </Select>
                            </Box>
                        </SimpleGrid>
                    </CardBody>
                </CardLoader>

                <CardLoader loading={qTreatment.isLoading}>
                    <CardHeader>
                        <Heading size='sm'>
                            Treatments
                        </Heading>
                    </CardHeader>
                    <CardBody pt={0}>
                        {
                            (treatments.length > 0) ?
                                <ListViewTreatments data={treatments}
                                    refetch={() => qTreatment.refetch()}
                                    update={() => { }}
                                    share={true}
                                />
                                :
                                <Empty description='No treatments yet for this patient 🫣' />
                        }
                    </CardBody>
                </CardLoader>

                <CardLoader loading={qRecord.isLoading}>
                    <CardHeader>
                        <Heading size='sm'>Notes</Heading>
                    </CardHeader>
                    <CardBody pt={0}>
                        {
                            (treatments.length > 0) ?
                                <Textarea h={'240px'} name={'notes'} defaultValue={rec.notes} />
                                :
                                <Empty description='No notes from the doctor were taken 🫣' />
                        }

                    </CardBody>
                </CardLoader>
            </VStack>


        </Box>
    );
}
