/*
    @TOUPDATE
    DEV URL: http://localhost:3030
    PROD URL: https://dentsysdb-api.herokuapp.com
*/
export const config = {
    url: 'https://dentsysdb-api.herokuapp.com',
    headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
    },
};

export async function fetchPageToken() {
    const response = await fetch(`${config.url}/generate/pageToken`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json'
        },
    });

    if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    return data;
}

export interface IFormRequestAccount {
    clinic_name: string;
    name: string;
    address: string;
    contact: string;
    email: string;
    username: string;
    password: string;

}
export async function sendMail({ accessToken, reqBody }: { accessToken: string, reqBody: IFormRequestAccount }) {
    const response = await fetch(`${config.url}/sendmail`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'authorization': accessToken,
        },
        body: JSON.stringify(reqBody),
    });

    if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    return data;
}
