import { AsyncSelect } from 'chakra-react-select';
import { IPatient, SearchPatients } from 'core/models/Patient';
import { debounce } from 'lodash';

interface Props {
    onChange: (value: string) => void;
}
export default function SearchPatientDropdown({ onChange }: Props) {
    return (
        <AsyncSelect
            name="patients"
            closeMenuOnSelect={true}
            loadOptions={debounce((inputValue, callback) => {
                SearchPatients(inputValue).then(async (res: IPatient[]) => {
                    const transformedOptions = await res.map((patient: IPatient) => ({
                        value: `${patient.lastname.toUpperCase()}, ${patient.firstname.toUpperCase()}`,
                        label: `${patient.lastname.toUpperCase()}, ${patient.firstname.toUpperCase()}`,
                    }));
                    callback(transformedOptions)
                });
            }, 500)}
            onChange={(v: any) => {
                onChange(v.value)
            }}
        />

    );
}