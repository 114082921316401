import { ReactNode } from 'react';
import {
    Box,
    Flex,
    HStack,
    useColorModeValue,
    Stack,
    Button
} from '@chakra-ui/react';
import { useNavigate } from "react-router-dom"
export default function Navbar({ children }: { children: ReactNode }) {
    const navigate = useNavigate();
    return (
        <>
            <Box bg={useColorModeValue('white', 'gray.800')}
                color={useColorModeValue('gray.600', 'white')}
                minH={'60px'}
                py={{ base: 2 }}
                px={{ base: 4 }}
                boxShadow={'md'}
                borderColor={useColorModeValue('gray.200', 'gray.900')}
            >
                <Flex h={16} alignItems={'center'} justifyContent={'space-between'}>
                    <HStack spacing={8} alignItems={'center'}>
                        <Box fontWeight={'600'}>Dentsys DB</Box>
                    </HStack>
                    <Flex alignItems={'center'}>
                        <Stack
                            flex={{ base: 1, md: 0 }}
                            justify={'flex-end'}
                            direction={'row'}
                            spacing={6}>
                            <Button
                                as={'a'}
                                fontSize={'sm'}
                                fontWeight={600}
                                color={'white'}
                                bg={'blue.400'}
                                _hover={{
                                    bg: 'blue.300',
                                }}
                                onClick={() => navigate('login')}
                            >
                                Login
                            </Button>
                            {/* <Login /> */}
                        </Stack>
                    </Flex>
                </Flex>
            </Box>

            <Box pt={1}>
                {children}
            </Box>
        </>
    );
}