import { Skeleton, Card, Stack } from '@chakra-ui/react';
import { ReactNode } from 'react';
import { } from 'react-icons/fi';
interface Props {
    loading: boolean;
    children: ReactNode;
}
export default function CardLoad({ loading, children }: Props) {
    return (
        <Card>
            {
                (loading) ?
                    <Stack p={4}>
                        <Skeleton height='20px' />
                        <Skeleton height='20px' />
                        <Skeleton height='20px' />
                        <Skeleton height='20px' />
                    </Stack> :
                    <>{children}</>
            }

        </Card>
    );
}
