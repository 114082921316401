import { config } from ".";
import { getUser } from "./Doctor";

export const uploadToS3AWS = async (formData: FormData) => {
    try {
        const response = await fetch(
            `${config.url}/s3/post`,
            {
                method: 'POST',
                body: formData,
            }
        );

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        return data;
    } catch (error) {
        console.error("An error occurred while uploading to S3:", error);
        return null;
    }
}

export interface IS3BucketImageData {
    idpatient: number;
    fileName: string;
    fileSizeInMegabytes: Float32Array;

    url?: string;
    width?: number;
    height?: number;
}

export const createS3BucketData = async (payload: IS3BucketImageData) => {
    try {
        const response = await fetch(
            `${config.url}/s3bucketdatastore`,
            {
                method: 'POST',
                headers: { ...config.headers, 'clinic_id': getUser().data.clinic_id, 'Authorization': `Bearer ${getUser().access_token}` },
                body: JSON.stringify(payload),
            }
        );

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        return data;
    } catch (error) {
        console.error("An error occurred while creating S3 bucket data:", error);
        return null;
    }
}


export const fetchS3BucketDataById = async (id: string = '') => {
    const ret = await fetch(
        `${config.url}/s3/${id}`,
        {
            method: 'GET', // or 'PUT'
            headers: { ...config.headers, 'clinic_id': getUser().data.clinic_id, 'Authorization': `Bearer ${getUser().access_token}` },
            // body: JSON.stringify(data),
        }
    )
        .then((response) => response.json())
        .then((data) => {
            return data;
        })
        .catch((error) => {
            return error;
        });

    return ret;
};

export const deleteS3BucketDataByFileName = async (fileName: string) => {
    const ret = await fetch(
        `${config.url}/s3bucketdatastore/${fileName}`,
        {
            method: 'DELETE', // or 'PUT'
            headers: { ...config.headers, 'clinic_id': getUser().data.clinic_id, 'Authorization': `Bearer ${getUser().access_token}` }
        }
    )
        .then((response) => response.json())
        .then((data) => {
            return data;
        })
        .catch((error) => {
            return error;
        });

    return ret;
}