import { Box, Button, ButtonGroup, Card, CardBody, Flex, Heading, Spacer } from '@chakra-ui/react';
import TablePatient from 'components/DataTable/TablePatient';
import FormPatientQuickAdd from 'components/Form/FormPatientQuickAdd';
import ModalForm from 'components/Modal/ModalForm';
import SearchInput from 'components/SearchInput/SearchInput';
import { getUser } from 'core/models/Doctor';
import { IPatient, SearchPatients } from 'core/models/Patient';
import { Message } from 'core/utils';
import { debounce } from 'lodash';
import { useState } from 'react';
import { FiPlus } from 'react-icons/fi';
import { useQueryClient, useMutation } from 'react-query';

export default function Patients() {
    const queryClient = useQueryClient();
    const user = getUser().data;
    const [show, setShow] = useState<boolean>(false);
    const [patients, setPatients] = useState<IPatient[]>([]);
    const { mutate, isLoading } = useMutation(SearchPatients, {
        onSuccess: (data) => {
            if (data.hasOwnProperty('message')) {
                Message('error', data.message);
                return
            }
            if (data.length === 0) Message('info', 'Patient not found.')
            setPatients(data);
        },
        onError: () => {
            console.error('ERROR: API Failed');
        },
        onSettled: () => {
            queryClient.invalidateQueries('login');
        },
    });
    const onSearch = debounce((searchValue: string) => {
        const arr = searchValue.split(" ");

        if (searchValue === "") return;
        if (arr.length > 2) {
            Message('warning', 'Can only search 2 names.')
        };
        mutate(searchValue);
    }, 1000);

    return (
        <Box m={4}>
            <Flex minWidth='max-content' alignItems='center' gap='2' pb={4}>
                <Box>
                    <Heading size='md'>Patients</Heading>
                </Box>
                <Spacer />
                <ButtonGroup gap='2'>
                    <Button colorScheme='blue'
                        onClick={() => {
                            if (user.limitExceeded) return Message('warning', 'You have reached the limit of your patient capacity')
                            setShow(true);
                        }}
                        leftIcon={<FiPlus />}>
                        Quick add
                    </Button>
                </ButtonGroup>
            </Flex>

            <Box>
                <SearchInput onChange={onSearch} />
            </Box>

            <Card mt={4}>
                <CardBody >
                    <TablePatient data={patients} isLoading={isLoading} />
                </CardBody>
            </Card>

            <ModalForm show={show} onToggle={() => setShow(!show)} title={'Patient form - quick add'}>
                <FormPatientQuickAdd onToggle={() => setShow(!show)} />
            </ModalForm>
        </Box>
    );
}
