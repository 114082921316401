import { FormControl, FormErrorMessage, FormLabel, Input, Box, VStack, Button, ButtonGroup, Spacer, Flex } from '@chakra-ui/react';
import { IFormRequestAccount, sendMail } from 'core/models';
import { Message } from 'core/utils';
import { Field, Formik, FormikHelpers } from "formik";
import { useMutation, useQueryClient } from 'react-query';
import * as Yup from "yup";
import Cookies from 'js-cookie';
import { useRef } from 'react';

export default function FormRequestAccount() {
    const queryClient = useQueryClient();
    const resetFormRef = useRef<() => void>(() => { });
    const mutation = useMutation(sendMail, {
        onSuccess: (data: any) => {
            if (data.mailsent) {
                Message('success', 'Request sent successfully!', 5000);
                resetFormRef.current();
                return
            }
            Message('error', 'Request failed!', 5000);
        },
        onError: () => {
            console.error('ERROR: API Failed');
        },
        onSettled: () => {
            queryClient.invalidateQueries('login');
        },
    });

    const initialValues = {
        clinic_name: "",
        name: "",
        address: "",
        contact: "",
        email: "",
        username: "",
        password: ""
    };
    const validationSchema = Yup.object({
        clinic_name: Yup.string().required('Required'),
        name: Yup.string().required('Required'),
        address: Yup.string().required('Required'),
        contact: Yup.string().required('Required'),
        email: Yup.string().email('Invalid email address'),
    });
    return (
        <Formik
            initialValues={initialValues}
            onSubmit={(values: IFormRequestAccount, { resetForm }: FormikHelpers<IFormRequestAccount>) => {
                const accessToken = Cookies.get('token') ?? '';
                resetFormRef.current = resetForm;
                mutation.mutate({ accessToken, reqBody: values });
            }}
            validationSchema={validationSchema}
        >
            {({ handleSubmit, errors, touched }) => (
                <VStack
                    spacing={4}
                    align='stretch'
                >
                    <Box >
                        <FormLabel htmlFor="clinic_name">Dental clinic name</FormLabel>
                        <FormControl isInvalid={!!errors.clinic_name && touched.clinic_name}>
                            <Field
                                as={Input}
                                id="clinic_name"
                                name="clinic_name"
                                type="text"
                                variant="filled"
                                color='black'
                                _focus={{ color: 'white'}}
                            />
                            <FormErrorMessage>{errors.clinic_name}</FormErrorMessage>
                        </FormControl>
                    </Box>
                    <Box>
                        <FormLabel htmlFor="address">Dental clinic's address</FormLabel>
                        <FormControl
                            isInvalid={!!errors.address && touched.address}
                        >
                            <Field
                                as={Input}
                                id="address"
                                name="address"
                                type="text"
                                variant="filled"
                                color='black'
                                _focus={{ color: 'white' }}
                            />
                            <FormErrorMessage>{errors.address}</FormErrorMessage>
                        </FormControl>
                    </Box>
                    <Box>
                        <FormLabel htmlFor="contact">Dental clinic's contact #</FormLabel>
                        <FormControl isInvalid={!!errors.contact && touched.contact}>
                            <Field
                                as={Input}
                                id="contact"
                                name="contact"
                                type="number"
                                variant="filled"
                                color='black'
                                _focus={{ color: 'white' }}
                            />
                            <FormErrorMessage>{errors.contact}</FormErrorMessage>
                        </FormControl>
                    </Box>
                    <Box >
                        <FormLabel htmlFor="name">Name of dentist</FormLabel>
                        <FormControl
                            isInvalid={!!errors.name && touched.name}
                        >
                            <Field
                                as={Input}
                                id="name"
                                name="name"
                                type="text"
                                variant="filled"
                                color='black'
                                _focus={{ color: 'white' }}
                            />
                            <FormErrorMessage>{errors.name}</FormErrorMessage>
                        </FormControl>
                    </Box>
                    <Box>
                        <FormLabel htmlFor="email">Email</FormLabel>
                        <FormControl isInvalid={!!errors.email && touched.email}>
                            <Field
                                as={Input}
                                id="email"
                                name="email"
                                type="email"
                                variant="filled"
                                color='black'
                                _focus={{ color: 'white' }}
                            />
                            <FormErrorMessage>{errors.email}</FormErrorMessage>
                        </FormControl>
                    </Box>
                    <Flex pb={4} pt={2}>
                        <Spacer />
                        <ButtonGroup spacing='6'>
                            <Button colorScheme='blue' onClick={() => handleSubmit()} isLoading={mutation.isLoading}>Send request</Button>
                        </ButtonGroup>
                    </Flex>


                </VStack>
            )}
        </Formik>
    )
}