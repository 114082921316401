import {
    Box,
    Center,
    chakra,
    Container,
    Stack,
    Text,
    useColorModeValue,
    VisuallyHidden,
} from '@chakra-ui/react';
import { FaFacebook, FaInstagram, FaYoutube } from 'react-icons/fa';
import { ReactNode } from 'react';
import { Link as RouterLink } from 'react-router-dom';

const SocialButton = ({
    children,
    label,
    href,
}: {
    children: ReactNode;
    label: string;
    href: string;
}) => {
    return (
        <chakra.button
            bg={useColorModeValue('blackAlpha.100', 'whiteAlpha.100')}
            rounded={'full'}
            w={8}
            h={8}
            cursor={'pointer'}
            as={'a'}
            href={href}
            display={'inline-flex'}
            alignItems={'center'}
            justifyContent={'center'}
            transition={'background 0.3s ease'}
            _hover={{
                bg: useColorModeValue('blackAlpha.200', 'whiteAlpha.200'),
            }}>
            <VisuallyHidden>{label}</VisuallyHidden>
            {children}
        </chakra.button>
    );
};

export default function SmallWithLogoLeft() {
    return (
        <Box
            bg={useColorModeValue('gray.50', 'gray.900')}
            color={useColorModeValue('gray.700', 'gray.200')}>
            <Container
                as={Stack}
                maxW={'6xl'}
                py={4}
                direction={{ base: 'column', md: 'row' }}
                spacing={4}
                justify={{ base: 'center', md: 'space-between' }}
                align={{ base: 'center', md: 'center' }}>
                {/* <Logo /> */}
                <Text>© 2023 Teng Huey. All rights reserved</Text>
                <Stack direction={'row'} spacing={6}>
                    <Center>
                        <Text as={'u'}>
                            <RouterLink to={'/privacy-policy'}>

                                Privacy Policy

                            </RouterLink>
                        </Text>
                        <Box m={'0px 12px'}>
                            ·
                        </Box>
                        <Text as={'u'}>
                            <RouterLink to={'/terms-of-service'}>
                                Terms
                            </RouterLink>
                        </Text>
                    </Center>
                    <SocialButton label={'Facebook'} href={'https://web.facebook.com/tengxi.sevilla/'}>
                        <FaFacebook />
                    </SocialButton>
                    <SocialButton label={'YouTube'} href={'https://www.youtube.com/channel/UC4rjBhqjLwG798b_Z0YRm5A'}>
                        <FaYoutube />
                    </SocialButton>
                    <SocialButton label={'Instagram'} href={'https://www.instagram.com/anthonysevillaa/'}>
                        <FaInstagram />
                    </SocialButton>
                </Stack>
            </Container>
        </Box>
    );
}