import { Box, Button, ButtonGroup, Card, CardBody, CardHeader, Flex, Heading, Select, SimpleGrid, Spacer, Text } from '@chakra-ui/react';
import CardStats from 'components/Card/CardStats';
import Empty from 'components/Empty/Empty';
import ListView from 'components/ListView/ListView';
import { getUser } from 'core/models/Doctor';
import { getAllPatientDateAdded, getAllPatientLastMod, getPatientSummary, IPatient } from 'core/models/Patient';
import { Message } from 'core/utils';
import { useEffect, useState } from 'react';
import { FiPlus } from 'react-icons/fi';
import { useQueryClient, useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';

export default function Dashboard() {
    const queryClient = useQueryClient();
    const user = getUser().data;
    const navigate = useNavigate();
    const [stat, setStat] = useState<{ all: number, active: number, inactive: number, storageSizeInGB: number }>({
        all: 0,
        active: 0,
        inactive: 0,
        storageSizeInGB: 0
    })
    const [filter, setFilter] = useState<'added' | 'updated'>('added');
    const [patients, setPatients] = useState<IPatient[]>([])
    const { mutate, isLoading } = useMutation(((filter === 'added') ? getAllPatientDateAdded : getAllPatientLastMod), {
        onSuccess: (data) => {
            setPatients(data);
        },
        onError: () => {
            console.error('ERROR: API Failed');
        },
        onSettled: () => {
            queryClient.invalidateQueries('fetch');
        },
    });

    // Removed Google API
    // const qCalendar = useQuery({
    //     queryKey: ['fetchCalendarListByRange'],
    //     queryFn: async () => {
    //         // GoogleEvents response
    //         const res = await fetchCalendarListByRange(moment().format('YYYY-MM-DD'), 'day');
    //         return res.data.items
    //     }
    // });

    useEffect(() => {
        init();
    }, []);
    useEffect(() => {
        mutate();
    }, [filter])

    const init = async () => {
        const x = await getPatientSummary();
        let { all, active, inactive, storageSizeInGB } = x;
        let tmp = {
            all: 0,
            active: 0,
            inactive: 0,
            storageSizeInGB: 0
        };
        await all.then((res: any) => tmp.all = res.total);
        // await active.then((res: any) => tmp.active = res.total); //unused
        await inactive.then((res: any) => tmp.inactive = res.total);
        await storageSizeInGB.then((res: any) => {
            tmp.storageSizeInGB = (res.total === null) ? 0 : Number(res.total.toFixed(4))
        });

        if (tmp.all > user.patientlimit && !user.limitExceeded) {
            user.limitExceeded = true;
            localStorage.setItem('user', JSON.stringify(user));
        }
        setStat(tmp);
    }

    return (
        <Box m={4}>
            <Flex minWidth='max-content' alignItems='center' gap='2' pb={4}>
                <Box>
                    <Heading
                        size='md'
                        fontFamily="Montserrat"
                    >
                        Dashboard
                    </Heading>
                </Box>
                <Spacer />
                <ButtonGroup gap='2'>
                    <Button
                        colorScheme='blue'
                        leftIcon={<FiPlus />}
                        onClick={() => {
                            if (user.limitExceeded) return Message('warning', 'You have reached the limit of your patient capacity')
                            navigate('/patientform')
                        }}
                    >
                        Patient Form
                    </Button>
                </ButtonGroup>
            </Flex>
            <Box mb={8}>
                <Heading
                    textAlign={'center'}
                    fontSize={'4xl'}
                    py={10}
                    fontFamily="Montserrat"
                    fontWeight={600}
                >
                    Welcome {user.clinic_name}
                    <Text fontSize={'lg'} fontWeight={400}>You're logged in as Dr. {user.name}</Text>
                </Heading>

                <CardStats data={stat} />
            </Box>

            <Box mb={8}>
                <SimpleGrid columns={[1, 1, 1, 2]} gap={6}>
                    <Card>
                        <CardHeader>
                            <Heading size='sm'>
                                Patient filters
                            </Heading>
                        </CardHeader>
                        <CardBody pt={0}>
                            <Box mb={4}>
                                <Select value={`${filter}`} variant='outline' onChange={(e: any) => setFilter(e.target.value)}>
                                    <option value='added'>Recently added</option>
                                    <option value='updated'>Recently updated</option>
                                </Select>
                            </Box>
                            {
                                (patients.length > 0) ?
                                    <ListView data={patients}
                                        loading={isLoading}
                                    />
                                    : <Empty description='🫣 Oops, no records found.' />
                            }


                        </CardBody>
                    </Card>
                    {/* <Card>
                        <CardHeader>
                            <Heading size='sm'>Scheduled today {formatDate(moment().toString(), 'll')}</Heading>
                        </CardHeader>
                        <CardBody pt={0}>
                            <ListViewCalendar data={(qCalendar.data !== undefined) ? qCalendar.data : []}
                                loading={qCalendar.isLoading}
                            />
                        </CardBody>
                    </Card> */}
                </SimpleGrid>
            </Box>


        </Box >



    );
}
