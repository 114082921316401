import { getDateToday } from "core/utils";
import { config } from ".";
import { getUser } from "./Doctor";

export interface IRecord {
    idpatient: string;
    oral_hyg: string;
    tongue: string;
    lips: string;
    gingi: string;
    circ_deposit: string;
    muc_mem: string;
    notes: string;
    last_modified: string
}

export const fetchRecord = async (id: string = '', clinicId: string = getUser().data.clinic_id) => {
    const ret = await fetch(
        `${config.url}/record/${id}`,
        {
            method: 'GET', // or 'PUT'
            headers: { ...config.headers, 'clinic_id': clinicId, 'Authorization': `Bearer ${getUser().access_token}` },
            // body: JSON.stringify(data),
        }
    )
        .then((response) => response.json())
        .then((data) => {
            return data;
        })
        .catch((error) => {
            return error;
        });

    return ret;
};


export interface ITreatment {
    idtreatment: string;
    idpatient: string;
    treatment: string;
    debit: string;
    credit: string;
    balance: string;
    date: string;
    last_modified: string;
}

export const fetchTreatments = async (id: string = '', clinicId: string = getUser().data.clinic_id) => {
    const ret = await fetch(
        `${config.url}/treatment/all/${id}`,
        {
            method: 'GET', // or 'PUT'
            headers: { ...config.headers, 'clinic_id': clinicId, 'Authorization': `Bearer ${getUser().access_token}` },
            // body: JSON.stringify(data),
        }
    )
        .then((response) => response.json())
        .then((data) => {
            return data;
        })
        .catch((error) => {
            return error;
        });

    return ret;
};

export const addTreatmentRow = async (id: string) => {
    const jsonBody = {
        idpatient: id,
        treatment: '',
        debit: '',
        credit: '',
        balance: '',
        date: getDateToday('YYYY-MM-DD'),
        status: '1',
        clinic_id: getUser().data.clinic_id
    }
    const ret = await fetch(
        `${config.url}/treatment`,
        {
            method: 'POST', // or 'PUT'
            headers: { ...config.headers, 'clinic_id': getUser().data.clinic_id, 'Authorization': `Bearer ${getUser().access_token}` },
            body: JSON.stringify(jsonBody),
        }
    )
        .then((response) => response.json())
        .then((data) => {
            return data;
        })
        .catch((error) => {
            return error;
        });

    return ret;
};

export interface IJSONTreatmentAttribute {
    idpatient: string,
    idtreatment: string,
    attribute: string,
    value: string
}
export const updateTreatmentAttribute = async (data: IJSONTreatmentAttribute) => {
    const ret = await fetch(
        `${config.url}/treatment/attribute`,
        {
            method: 'PUT', // or 'PUT'
            headers: { ...config.headers, 'clinic_id': getUser().data.clinic_id, 'Authorization': `Bearer ${getUser().access_token}` },
            body: JSON.stringify(data),
        }
    )
        .then((response) => response.json())
        .then((data) => {
            return data;
        })
        .catch((error) => {
            return error;
        });

    return ret;
};

export const deleteTreatmentRow = async (id: string) => {
    const ret = await fetch(
        `${config.url}/treatment/${id}`,
        {
            method: 'DELETE', // or 'PUT'
            headers: { ...config.headers, 'clinic_id': getUser().data.clinic_id, 'Authorization': `Bearer ${getUser().access_token}` },
            // body: JSON.stringify(data),
        }
    )
        .then((response) => response.json())
        .then((data) => {
            return data;
        })
        .catch((error) => {
            return error;
        });

    return ret;
};

export interface IJSONRecordAttribute {
    idpatient: string,
    attribute: string,
    value: string
}
export const updateRecordAttribute = async (data: IJSONRecordAttribute) => {
    const ret = await fetch(
        `${config.url}/record/attribute`,
        {
            method: 'PUT', // or 'PUT'
            headers: { ...config.headers, 'clinic_id': getUser().data.clinic_id, 'Authorization': `Bearer ${getUser().access_token}` },
            body: JSON.stringify(data),
        }
    )
        .then((response) => response.json())
        .then((data) => {
            return data;
        })
        .catch((error) => {
            return error;
        });

    return ret;
};