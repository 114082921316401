import {
    Flex,
    Heading,
    Image,
    Stack,
    Text,
    useBreakpointValue,
} from '@chakra-ui/react';
import HeroImage from './../../core/assets/images/heroimage.jpg';

export default function SplitScreen() {

    return (
        <Stack minH={'100vh'} direction={{ base: 'column', md: 'row' }}>
            <Flex p={8} flex={1} align={'center'} justify={'center'}>
                <Stack spacing={6} w={'full'} maxW={'lg'}>
                    <Heading fontSize={{ base: '3xl', md: '4xl', lg: '5xl' }}>
                        <Text
                            as={'span'}
                            position={'relative'}
                            _after={{
                                content: "''",
                                width: 'full',
                                height: useBreakpointValue({ base: '20%', md: '30%' }),
                                position: 'absolute',
                                bottom: 1,
                                left: 0,
                                bg: 'blue.400',
                                zIndex: -1,
                            }}>
                            Storing information made easy
                        </Text>
                        <br />{' '}
                        <Text color={'blue.400'} as={'span'}>

                        </Text>{' '}
                    </Heading>
                    <Text fontSize={{ base: 'md', lg: 'lg' }} color={'gray.500'}>
                        Paperless systems offer a smarter, faster, and more sustainable way of managing information. By digitizing your documents and workflows, you can reduce your paper consumption, improve efficiency, save time, and enhance security. You can easily access, from any device, anywhere, and anytime. Plus, you can eliminate the risk of misplacing or losing important documents, and free up valuable office space. By going paperless, you're not only making your business more efficient but also contributing to a cleaner and greener environment.
                    </Text>
                    <Stack direction={{ base: 'column', md: 'row' }} spacing={4}>
                        {/* <Button
                            rounded={'full'}
                            bg={'blue.400'}
                            color={'white'}
                            _hover={{
                                bg: 'blue.500',
                            }}>
                            Contact Us
                        </Button> */}
                        {/* <Button rounded={'full'}>How It Works</Button> */}
                    </Stack>
                </Stack>
            </Flex>
            <Flex flex={1}>
                <Image
                    alt={'Login Image'}
                    objectFit={'cover'}
                    src={HeroImage}
                />
            </Flex>
        </Stack>
    );
}