import { ArrowBackIcon } from '@chakra-ui/icons';
import { Text, Button, Heading, Box, Flex, Container, VStack } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
export default function TermsOfService() {
    const navigate = useNavigate();
    return (
        <Box bg={'white'}>
            <Box bg="blue.400" w="100%">
                <Container maxW='container.md'>
                    <Flex justify="space-between" align="center" h={20}>
                        <Heading as="h1" fontSize="2xl" color={'white'}>
                            Dentsys DB
                        </Heading>
                        {/* Add any other navbar elements here */}
                    </Flex>
                </Container>
            </Box>
            <Container maxW='container.md' mb={16}>
                <VStack
                    align='stretch'
                >
                    <Box pt={2}>
                        <Button leftIcon={<ArrowBackIcon />}
                            variant='ghost'
                            size='sm'
                            onClick={() => navigate('/')}
                        >
                            Back
                        </Button>
                    </Box>
                    <Box>
                        <Text fontSize='md' color={'blue.400'} as='b'>Last updated March 31, 2023</Text>

                    </Box>
                    <Box>
                        <Heading as='h3' size='lg'>
                            Terms of Service
                        </Heading>
                    </Box>
                    <Box>
                        Welcome to DentsysDB App, or our Patient Information System, Records Management System, and Schedule Management System ("the System"). The System is owned and operated by Spooting ("we," "us," "our").<br /><br />
                        By accessing or using the System, you agree to be bound by these Terms of Service ("Terms"). Please read these Terms carefully before using the System.
                    </Box>
                    <br />
                    <Box>
                        <Heading as='h4' size='md'>
                            Access and Use of the System
                        </Heading>
                    </Box>
                    <Box>
                        You may only access and use the System for lawful purposes and in accordance with these Terms. You are responsible for ensuring that all persons who access the System through your account are aware of these Terms and comply with them.
                    </Box>
                    <br />
                    <Box>
                        <Heading as='h4' size='md'>
                            User Accounts
                        </Heading>
                    </Box>
                    <Box>
                        You may need to create an account to access certain features of the System. You are responsible for maintaining the confidentiality of your account and password, and for restricting access to your computer or mobile device. You are also responsible for all activities that occur under your account or password.
                    </Box>
                    <br />
                    <Box>
                        <Heading as='h4' size='md'>
                            Privacy
                        </Heading>
                    </Box>
                    <Box>
                        Our use of your personal information is governed by our Privacy Policy, which is incorporated into these Terms.
                    </Box>
                    <br />
                    <Box>
                        <Heading as='h4' size='md'>
                            Intellectual Property
                        </Heading>
                    </Box>
                    <Box>
                        The System and its entire contents, features, and functionality are owned by us or our licensors and are protected by United States and international copyright, trademark, patent, trade secret, and other intellectual property or proprietary rights laws. </Box>
                    <br />
                    <Box>
                        <Heading as='h4' size='md'>
                            Prohibited Activities
                        </Heading>
                    </Box>
                    <Box>
                        You may not use the System for any illegal or unauthorized purpose, or in any manner that could damage, disable, overburden, or impair the System. You may not attempt to gain unauthorized access to any part of the System, or to any other accounts, computer systems, or networks connected to the System. </Box>
                    <br />
                    <Box>
                        <Heading as='h4' size='md'>
                            Disclaimers
                        </Heading>
                    </Box>
                    <Box>
                        THE SYSTEM IS PROVIDED ON AN "AS IS" AND "AS AVAILABLE" BASIS, WITHOUT WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NON-INFRINGEMENT.
                    </Box>
                    <br />
                    <Box>
                        <Heading as='h4' size='md'>
                            Limitation of Liability
                        </Heading>
                    </Box>
                    <Box>
                        IN NO EVENT SHALL WE, OUR DIRECTORS, OFFICERS, EMPLOYEES, AGENTS, OR AFFILIATES BE LIABLE FOR ANY DAMAGES WHATSOEVER, INCLUDING INDIRECT, INCIDENTAL, SPECIAL, PUNITIVE, OR CONSEQUENTIAL DAMAGES, ARISING OUT OF OR IN CONNECTION WITH YOUR USE OF THE SYSTEM.
                    </Box>
                    <br />
                    <Box>
                        <Heading as='h4' size='md'>
                            Indemnification
                        </Heading>
                    </Box>
                    <Box>
                        You agree to indemnify, defend, and hold harmless us, our directors, officers, employees, agents, and affiliates from and against any and all claims, damages, obligations, losses, liabilities, costs, debts, or expenses (including attorney's fees) arising from your use of the System, your violation of these Terms, or your violation of any rights of another.
                    </Box>
                    <br />
                    <Box>
                        <Heading as='h4' size='md'>
                            Modification and Termination
                        </Heading>
                    </Box>
                    <Box>
                        We reserve the right to modify or terminate the System or these Terms at any time, without prior notice.
                    </Box>
                    <br />
                    <Box>
                        <Heading as='h4' size='md'>
                            Governing Law
                        </Heading>
                    </Box>
                    <Box>
                        These Terms shall be governed by and construed in accordance with the laws of the Republic of the Philippines, without giving effect to any principles of conflicts of law.
                    </Box>
                    <br />
                    <Box>
                        <Heading as='h4' size='md'>
                            Entire Agreement
                        </Heading>
                    </Box>
                    <Box>
                        These Terms, together with our Privacy Policy, constitute the entire agreement between you and us with respect to the System, and supersede all prior or contemporaneous communications and proposals, whether oral or written, between you and us.
                    </Box>
                    <br />
                    <Box>
                        <Heading as='h4' size='md'>
                            Contact Us
                        </Heading>
                    </Box>
                    <Box>
                        If you have any questions or concerns regarding our Privacy Policy, please contact us at sevillanthonyy@gmail.com.
                    </Box>
                </VStack>
            </Container>
        </Box>
    );
}
