import {
    Flex,
    Heading,
    Input,
    Button,
    FormControl,
    useColorModeValue,
    VStack,
    Box,
    Checkbox,
    FormLabel,
    Stack,
    Text,
    Center,
} from '@chakra-ui/react';
import * as Yup from 'yup';
import { Formik, Field } from 'formik';
import { useNavigate, Link as RouterLink } from 'react-router-dom';
import { Message } from 'core/utils';
import { Account, AuthLogin, ILoginFormValues } from 'core/models/Doctor';
import { useMutation, useQueryClient } from 'react-query';
// import { CodeResponse, useGoogleLogin } from '@react-oauth/google';
// import { GoogleOAuth } from 'core/models/GoogleAPIs';
// import jwt_decode from "jwt-decode";

const ValidationSchema = Yup.object().shape({
    username: Yup.string()
        .required('Required field'),
    password: Yup.string()
        .required('Required field'),
});

export default function Login() {
    const queryClient = useQueryClient();
    const navigate = useNavigate();
    // const authorizeGoogle = useGoogleLogin({
    //     onSuccess: (codeResponse: CodeResponse) => {
    //         console.log(codeResponse)
    //         GoogleOAuth(codeResponse).then((tokens) => {
    //             console.log(tokens);
    //             const userObject = jwt_decode(tokens.id_token);
    //             console.log(userObject);
    //             localStorage.setItem('gtokens', JSON.stringify(tokens));
    //             navigate('/dashboard');
    //             Message('success', 'Logged in');
    //         })
    //     },
    //     flow: 'auth-code',
    //     scope: 'https://www.googleapis.com/auth/calendar',
    //     // select_account: true
    // });
    const { mutate, isLoading } = useMutation(AuthLogin, {
        onSuccess: (data) => {
            if (data.hasOwnProperty('message')) {
                Message('error', data.message);
                return
            }
            const account: Account = data;
            localStorage.setItem('user', JSON.stringify(account));
            // authorizeGoogle();  // Removed Google API
            navigate('/dashboard');
            Message('success', 'Logged in');
        },
        onError: () => {
            console.error('ERROR: API Failed');
        },
        onSettled: () => {
            queryClient.invalidateQueries('login');
        },
    });

    return (
        <Flex
            minH={'100vh'}
            align={'center'}
            justify={'center'}
            bg={useColorModeValue('gray.50', 'gray.800')}>
            <Stack spacing={8} mx={'auto'} maxW={'lg'} py={12} px={6}>
                <Stack align={'center'}>
                    <Heading fontSize={'4xl'}>Sign in to your account</Heading>
                    <Text fontSize={'lg'} color={'gray.600'}>
                        to enjoy all of our cool features ✌️
                    </Text>
                </Stack>
                <Box
                    rounded={'lg'}
                    bg={useColorModeValue('white', 'gray.700')}
                    boxShadow={'lg'}
                    p={8}>
                    <Stack spacing={4}>
                        <Formik
                            initialValues={{
                                username: "",
                                password: ""
                            }}
                            validationSchema={ValidationSchema}
                            onSubmit={(values: ILoginFormValues, actions) => {
                                mutate(values);
                            }}
                        >
                            {({ handleSubmit, errors, touched, isSubmitting }) => (
                                <form onSubmit={handleSubmit}>
                                    <VStack spacing={4} align="flex-start">
                                        <FormControl isInvalid={!!errors.username && touched.username}>
                                            <FormLabel>Username</FormLabel>
                                            <Field
                                                as={Input}
                                                id="username"
                                                name="username"
                                                type="text"
                                                variant="filled"
                                                placeholder="Chickenjoy"
                                            />
                                            {/* <FormErrorMessage>{errors.password}</FormErrorMessage> */}
                                        </FormControl>
                                        <FormControl isInvalid={!!errors.password && touched.password}>
                                            <FormLabel>Password</FormLabel>
                                            <Field
                                                as={Input}
                                                id="password"
                                                name="password"
                                                type="password"
                                                variant="filled"
                                                placeholder="****"
                                            />
                                            {/* <FormErrorMessage>{errors.password}</FormErrorMessage> */}
                                        </FormControl>

                                        <Stack spacing={4} width="full">
                                            <Stack
                                                direction={{ base: 'column', sm: 'row' }}
                                                align={'start'}
                                                justify={'space-between'}

                                            >
                                                <Checkbox>Remember me</Checkbox>
                                            </Stack>
                                            <Button
                                                type="submit" isLoading={isLoading}
                                                bg={'blue.400'}
                                                color={'white'}
                                                _hover={{
                                                    bg: 'blue.500',
                                                }}>
                                                Sign in
                                            </Button>
                                            <Center>
                                                <Text as={'u'}>
                                                    <RouterLink to={'/privacy-policy'}>

                                                        Privacy Policy

                                                    </RouterLink>
                                                </Text>
                                                <Box m={'0px 12px'}>
                                                    ·
                                                </Box>
                                                <Text as={'u'}>
                                                    <RouterLink to={'/terms-of-service'}>
                                                        Terms
                                                    </RouterLink>
                                                </Text>
                                            </Center>
                                        </Stack>
                                    </VStack>
                                </form>
                            )}
                        </Formik>

                    </Stack>

                </Box>
            </Stack >
        </Flex >
    );
};