import { formatDate } from "core/utils";
import { config } from ".";
import { getUser } from "./Doctor";

export interface IReportPayload {
    startDate: string;
    endDate: string;
}
export interface IResponseTreatmentChart {
    record_date: string;
    total: number;
    paid: number;
}
export const fetchTreatmentChart = async (data: IReportPayload) => {
    const ret = await fetch(
        `${config.url}/report/treatments_total?startDate=${formatDate(data.startDate, "YYYY-MM-DD")}&endDate=${formatDate(data.endDate, "YYYY-MM-DD")}`,
        {
            method: 'GET', // or 'PUT'
            headers: { ...config.headers, 'clinic_id': getUser().data.clinic_id, 'Authorization': `Bearer ${getUser().access_token}` },
            // body: JSON.stringify(data),
        }
    )
        .then((response) => response.json())
        .then((data) => {
            return data;
        })
        .catch((error) => {
            return error;
        });

    return ret;
};

export interface IResponsePatientVisitChart {
    record_date: string;
    count: number;
}

export const fetchPatientVisitsChart = async (data: IReportPayload) => {
    const ret = await fetch(
        `${config.url}/report/patient_visits?startDate=${formatDate(data.startDate, "YYYY-MM-DD")}&endDate=${formatDate(data.endDate, "YYYY-MM-DD")}`,
        {
            method: 'GET', // or 'PUT'
            headers: { ...config.headers, 'clinic_id': getUser().data.clinic_id, 'Authorization': `Bearer ${getUser().access_token}` },
            // body: JSON.stringify(data),
        }
    )
        .then((response) => response.json())
        .then((data) => {
            return data;
        })
        .catch((error) => {
            return error;
        });

    return ret;
};

export interface IResponseOustandingBalance {
    idpatient: string;
    balance: number;
    name: string;
    contact: string;
    email: string;
}

export const fetchOutstandingBalance = async (data: IReportPayload) => {
    const ret = await fetch(
        `${config.url}/report/outstanding_balance?startDate=${formatDate(data.startDate, "YYYY-MM-DD")}&endDate=${formatDate(data.endDate, "YYYY-MM-DD")}`,
        {
            method: 'GET', // or 'PUT'
            headers: { ...config.headers, 'clinic_id': getUser().data.clinic_id, 'Authorization': `Bearer ${getUser().access_token}` },
            // body: JSON.stringify(data),
        }
    )
        .then((response) => response.json())
        .then((data) => {
            return data;
        })
        .catch((error) => {
            return error;
        });

    return ret;
};