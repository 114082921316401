import { Card, Box, Button, Text } from '@chakra-ui/react';
import { useState } from 'react';
import { FiTrash2 } from 'react-icons/fi';
import { Calendar as BigCalendar, momentLocalizer } from 'react-big-calendar'
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import './styles.scss';
import ModalForm from 'components/Modal/ModalForm';
import SearchPatientDropdown from 'components/SearchInput/SearchPatientDropdown';
import { createCalendarEvent, deleteCalendarEvent } from 'core/models/GoogleAPIs';
import { Message } from 'core/utils';
const localizer = momentLocalizer(moment);

export interface IEvent {
    id: string;
    allDay?: boolean;
    title: React.ReactNode;
    start: Date;
    end: Date;
}

interface Props {
    onChange: (date: string) => void;
    data: IEvent[];
    refetch: () => void;
}

export default function MyCalendar({ onChange, data, refetch }: Props) {
    const [showAdd, setShowAdd] = useState<boolean>(false);
    const [showDisplay, setShowDisplay] = useState<boolean>(false);
    const [selectedTimeSlot, setSelectedTimeslot] = useState<{ start: string, end: string }>({ start: '', end: '' });
    const [selectedPatient, setSelectedPatient] = useState<string>('');
    const [selectedEventId, setSelectedEventId] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);

    const onSubmit = async () => {
        try {
            if (selectedPatient === '') return;
            setLoading(true);
            const res = await createCalendarEvent({
                summary: selectedPatient,
                ...selectedTimeSlot
            });
            if (![200, 204].includes(res.status)) {
                Message('error', res.statusText);
                return
            }
            refetch();
        } catch (error) {
            console.error(`Error in onSubmit: ${error}`);
        } finally {
            setLoading(false);
            setShowAdd(false);
        }
    }

    const onDelete = async () => {
        try {
            if (selectedEventId === '') return;
            setLoading(true);
            const res = await deleteCalendarEvent(selectedEventId);
            if (![200, 204].includes(res.status)) {
                Message('error', res.statusText);
                return
            }
            Message('success', 'Succesfully removed.');
            refetch();
        } catch (error) {
            console.error(`Error in onSubmit: ${error}`);
        } finally {
            setLoading(false);
            setShowDisplay(false);
        }
    }
    return (
        <Card h={'672px'} p={4}>
            <BigCalendar
                localizer={localizer}
                views={{
                    week: true
                }}
                view={'week'}
                events={data}
                startAccessor="start"
                endAccessor="end"
                defaultDate={moment().toDate()}

                onNavigate={(newDate, view, action) => {
                    console.log({ newDate, view, action });
                    const date = moment(newDate).format('YYYY-MM-DD');
                    onChange(date);
                    // setFilter(data.toString());
                }}
                selectable
                onSelectSlot={({ start, end }) => {
                    setSelectedTimeslot({ start: moment(start).toISOString(), end: moment(end).toISOString() })
                    setShowAdd(true);
                    setSelectedPatient('');
                }}
                onSelectEvent={(event) => {
                    const { title, start, end, id } = event;
                    setSelectedTimeslot({ start: moment(start).toISOString(), end: moment(end).toISOString() });
                    setSelectedPatient(`${title}`);
                    setSelectedEventId(id);
                    setShowDisplay(true);
                }}
            />
            <ModalForm show={showAdd} onToggle={() => setShowAdd(!showAdd)} title={`Add on ${moment(selectedTimeSlot.start).format('lll')}`}>
                <Box pb={4}>
                    <SearchPatientDropdown onChange={setSelectedPatient} />
                    <Button colorScheme='blue' mt={4} mb={2} float={'right'}
                        onClick={() => onSubmit()}
                        isLoading={loading}
                    >
                        Submit
                    </Button>
                </Box>
            </ModalForm>

            <ModalForm show={showDisplay} onToggle={() => setShowDisplay(!showDisplay)} title={`${moment(selectedTimeSlot.start).format('lll')}`}
                bgBlur={false}
            >
                <Box pb={4}>
                    <Text fontSize='xl'>{selectedPatient}</Text>
                    <Button colorScheme='red' mt={4} mb={2} float={'right'}
                        leftIcon={<FiTrash2 />}
                        onClick={() => onDelete()}
                        isLoading={loading}
                    >
                        Remove
                    </Button>
                </Box>
            </ModalForm>
        </Card>




    );
}