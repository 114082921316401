import { Button } from '@chakra-ui/react';
import { createS3BucketData, uploadToS3AWS } from 'core/models/S3Bucket';
import { Message } from 'core/utils';
import { FaUpload } from "react-icons/fa";
import { useParams } from 'react-router-dom';

interface Props {
    onClick?: () => void;
}

export default function ButtonUpload({ onClick }: Props) {
    const { patientId } = useParams();

    const handleFileUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0] ?? null;
        const formData = new FormData();
        if (file && file.type.startsWith('image/')) {
            Message("info", "Uploading image...");
            formData.append("image", file);
            formData.append("idpatient", `${patientId}`);
            const ret = await uploadToS3AWS(formData);
            if (ret.status === "success") {

                await createS3BucketData(ret).then((data) => {
                    if (data) {
                        Message("success", "Image uploaded successfully.");
                        onClick && onClick();
                    }
                });
            } else {
                Message("error", "Upload failed.");
            }
        } else {
            Message("error", "Please select an image file.");
        }
    };

    return (
        <Button type='submit' as="label" htmlFor="fileInput" colorScheme="blue" cursor="pointer" leftIcon={<FaUpload />} iconSpacing={0}>
            <input id="fileInput" type="file" style={{ display: "none" }} onChange={handleFileUpload} name='image' formEncType={'multipart/form-data'} />
        </Button>
    );
}
