import { Box, Button, ButtonGroup, CardBody, Flex, Heading, Spacer, Card, Text, Stack, CardHeader, Divider, ListItem, List, ListIcon } from '@chakra-ui/react';
import { AuthLogout, getUser } from 'core/models/Doctor';
import { FiLogOut } from 'react-icons/fi';
import { MdCheckCircle, MdCancel } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';

export default function Account() {
    const navigate = useNavigate();
    const accountDetail = getUser().data;
    const isBasic = accountDetail.patientlimit === 100;
    const icon = isBasic ? MdCancel : MdCheckCircle;
    const color = isBasic ? 'red.500' : 'green.500';

    const logout = () => {
        navigate('/');
        AuthLogout();
    }

    return (
        <Box m={4}>
            <Flex minWidth='max-content' alignItems='center' gap='2' pb={4}>
                <Box>
                    <Heading size='md'></Heading>
                </Box>
                <Spacer />
                <ButtonGroup gap='2'>
                    <Button colorScheme='blue' leftIcon={<FiLogOut />} onClick={logout}> Logout</Button>
                </ButtonGroup>
            </Flex>

            <Stack spacing={5}>
                <Card>
                    <CardHeader pb={0}>
                        <Heading size='md'>Account details</Heading>
                    </CardHeader>
                    <CardBody>
                        <Stack spacing={2}>
                            <Text fontSize="md">Clinic Name: {accountDetail.clinic_name}</Text>
                            <Text fontSize="md">Clinic Address: {accountDetail.clinic_address}</Text>
                            <Text fontSize="md">Clinic Contact: {accountDetail.clinic_contact}</Text>
                            <br />
                            <Text fontSize="md">Name: {accountDetail.name}</Text>
                            <Text fontSize="md">Email: {accountDetail.email}</Text>
                            <Text fontSize="md">Username: {accountDetail.username}</Text>
                            <Text fontSize="md">Password: *******</Text>

                            <Divider />
                            <Text fontSize="xs">For any account updates kindly contact me through this email sevillanthonyy@gmail.com</Text>
                        </Stack>
                    </CardBody>
                </Card>
                <Card>
                    <CardBody>
                        <Stack spacing={2}>
                            <Text fontSize="2xl">Tier: {isBasic ? 'Basic' : 'Premium'}</Text>
                            <Text fontSize="md">Features:</Text>
                            <List spacing={3}>
                                <ListItem>
                                    <ListIcon as={MdCheckCircle} color='green.500' />
                                    Patient List {(isBasic ? ' (100 patients)' : ' (Unlimited)')}
                                </ListItem>
                                <ListItem>
                                    <ListIcon as={MdCheckCircle} color='green.500' />
                                    Treatment Records
                                </ListItem>
                                <ListItem>
                                    <ListIcon as={icon} color={color} />
                                    Image Storage. Free (5 GB), succeeding GB is charged per 1 GB per month
                                </ListItem>
                                <ListItem>
                                    <ListIcon as={icon} color={color} />
                                    Patient & Treatment Reports
                                </ListItem>
                                {/* Remove for now @TODO */}
                                {/* <ListItem>
                                    <ListIcon as={icon} color={color} />
                                    Personalized domain (as per client request)
                                </ListItem>
                                <ListItem>
                                    <ListIcon as={icon} color={color} />
                                    2 Custom Features (as per client request)
                                </ListItem>
                                <ListItem>
                                    <ListIcon as={icon} color={color} />
                                    Personalized landing page (as per client request)
                                </ListItem> */}
                            </List>
                            {
                                isBasic ?
                                    <>
                                        <Divider />
                                        <Text fontSize="xs">To request for an upgrade, you contact me through this email sevillanthonyy@gmail.com</Text>
                                    </>
                                    :
                                    <>
                                        <Divider />
                                        <Text fontSize="xs">For personalized requests, you contact me through this email sevillanthonyy@gmail.com</Text>
                                    </>
                            }
                        </Stack>

                    </CardBody>
                </Card>
            </Stack>
        </Box >
    );
}
