
import { FormControl, Input, FormLabel, StackDivider, VStack, SimpleGrid, Stack, Textarea, IconButton, Box } from '@chakra-ui/react';
import { deleteTreatmentRow, ITreatment, updateTreatmentAttribute } from 'core/models/Record';
import { formatDate, Message } from 'core/utils';
import { debounce } from 'lodash';
import { useState } from 'react';
import { FiTrash2 } from 'react-icons/fi';
import { useParams } from 'react-router-dom';

interface Props {
    data: ITreatment[];
    refetch: () => void;
    update: () => void;
    share: boolean;
}

export default function ListViewTreatments({ data, refetch, update, share }: Props) {
    const { patientId } = useParams();
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const handleChange = debounce(async (idtreatment: string, attribute: string, value: string) => {
        await updateTreatmentAttribute({ idpatient: (patientId) ? patientId : '', idtreatment, attribute, value });
        update();
    }, 500);

    const handleDeleteRow = async (id: string) => {
        setIsSubmitting(true);
        const fetch = await deleteTreatmentRow(id);
        if (fetch.hasOwnProperty('message')) {
            Message('error', fetch.message);
            setIsSubmitting(false);
            return
        }
        Message('success', 'Row deleted...');
        refetch();
        setIsSubmitting(false);
    }
    return (
        <Stack divider={<StackDivider borderColor={'#3182CE'} opacity={.5} />} spacing='8'>
            {

                data.map((x, i) => (
                    <VStack alignItems={'normal'} gap={2} key={i}>
                        <FormControl variant="floating" w={'100%'} display={'flex'}>
                            <Box w={'256px'}>
                                <Input
                                    name='date'
                                    placeholder="DD/MM/YYYY"
                                    size="md"
                                    type="date"
                                    defaultValue={formatDate(x.date, 'YYYY-MM-DD')}
                                    onChange={(e) => handleChange(x.idtreatment, e.target.name, e.target.value)}
                                />
                                <FormLabel >Date</FormLabel>
                            </Box>
                            <Box style={{ marginLeft: 'auto' }} display={share ? 'none' : ''}>
                                <IconButton aria-label='Delete row' icon={<FiTrash2 />} bgColor='gray.200'
                                    isLoading={isSubmitting} onClick={() => handleDeleteRow(x.idtreatment)}
                                />
                            </Box>

                        </FormControl>

                        <FormControl variant="floating" >
                            <Textarea
                                name='treatment'
                                size="md"
                                defaultValue={x.treatment}
                                onChange={(e) => handleChange(x.idtreatment, e.target.name, e.target.value)}
                            />
                            <FormLabel >Description</FormLabel>
                        </FormControl>
                        {
                            (share) ? <></>
                                : <SimpleGrid columns={[1, 1, 2, 2]} spacing={4}>
                                    <FormControl variant="floating" >
                                        <Input
                                            name='debit'
                                            size="md"
                                            type="number"
                                            defaultValue={x.debit}
                                            onChange={(e) => handleChange(x.idtreatment, e.target.name, e.target.value)}
                                        />
                                        <FormLabel >Total</FormLabel>
                                    </FormControl>
                                    <FormControl variant="floating" >
                                        <Input
                                            name='credit'
                                            size="md"
                                            type="number"
                                            defaultValue={x.credit}
                                            onChange={(e) => handleChange(x.idtreatment, e.target.name, e.target.value)}
                                        />
                                        <FormLabel >Paid</FormLabel>
                                    </FormControl>
                                </SimpleGrid>
                        }
                    </VStack>
                ))
            }

        </Stack>

    );
}