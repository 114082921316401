import {
  ChakraProvider,
} from "@chakra-ui/react";
import "./App.scss";
import Sidebar from "components/Sidebar/Sidebar"
import { Route, Routes, useNavigate } from "react-router-dom"
import Dashboard from "pages/Dashboard"
import Patients from "pages/Patients"
import Record from "pages/Record"
import PatientForm from "pages/PatientForm"
import Account from "pages/Account";
import Calendar from "pages/Calendar"
import Reports from "pages/Reports"
import PrivacyPolicy from "components/PrivacyPolicy"
import TermsOfService from "components/TermsOfService"
import Home from "pages/Home"
import { useEffect } from "react"
import { getUser } from "core/models/Doctor"
import Login from "pages/Login"
import RecordShare from "pages/RecordShare"
import theme from "./extendTheme";

export const App = () => {
  const navigate = useNavigate();
  const currentPage = window.location.pathname;
  const RECORD_SHARE = '/share';
  useEffect(() => {
    // Removed Google API
    // if (Object.keys(getUser().data).length === 0 && Object.keys(getGoogleTokens()).length === 0) {
    //   console.log('Redirecting')
    //   navigate('/')
    // }
    const userData = getUser().data;
    if (userData && Object.keys(userData).length === 0 && currentPage && !currentPage.includes(RECORD_SHARE)) {
      console.log('Redirecting')
      navigate('/')
    }
  }, [])
  return (
    <ChakraProvider theme={theme}>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/dashboard" element={<Sidebar><Dashboard />   </Sidebar>} />
        <Route path="/patients" element={<Sidebar><Patients />   </Sidebar>} />
        <Route path="/calendar" element={<Sidebar><Calendar />   </Sidebar>} />
        <Route path="/reports" element={<Sidebar><Reports />   </Sidebar>} />
        <Route path="/account" element={<Sidebar><Account />   </Sidebar>} />
        <Route path="/record/:patientId" element={<Sidebar><Record />   </Sidebar>} />
        <Route path="/share" element={<RecordShare />} />
        <Route path="/patientform" element={<Sidebar><PatientForm />   </Sidebar>} />

        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-of-service" element={<TermsOfService />} />
      </Routes>

    </ChakraProvider>
  )
}
