import { IResponseTreatmentChart } from "core/models/Report";
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer
} from "recharts";

interface Props {
    data: IResponseTreatmentChart[];
}
export default function TransactionsChart(props: Props) {
    return (
        <ResponsiveContainer width="100%" height="100%">
            <BarChart
                width={500}
                height={300}
                data={props.data}
                margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5
                }}
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="record_date" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="total" fill="#FF7D31" />
                <Bar dataKey="paid" fill="#3182CE" />
            </BarChart>
        </ResponsiveContainer>
    );
}
