import { Stack, Input, FormControl, FormLabel, Text, Box } from '@chakra-ui/react';
import { useState } from 'react';

interface Props {
    onChange: (startDate: string, endDate: string) => void;
}
export default function DateRangePicker(props: Props) {
    // State to store selected dates
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [validationError, setValidationError] = useState('');

    // Function to handle the selection of the start date
    const handleStartDateChange = (date: any) => {
        const { value } = date.target;
        setStartDate(value);
        validateDates(value, endDate ?? '');
    };

    // Function to handle the selection of the end date
    const handleEndDateChange = (date: any) => {
        const { value } = date.target;
        setEndDate(value);
        validateDates(startDate ?? '', value);
    };
    const validateDates = (start: string, end: string) => {
        console.log({ start, end })
        if (start && end === null) {
            // Do nothing
        } else if (start && end === '') {
            // Do nothing
        } else if (start && end && start > end) {
            setValidationError('Start date cannot be after end date');
        } else {
            setValidationError('');
            props.onChange(start ?? '', end ?? '')
        }
    };

    return (
        <Box>
            <Stack spacing='24px' direction={{ base: 'column', md: 'row' }} align={{ base: 'center', md: 'stretch' }}>
                <FormControl>
                    <FormLabel>Start date:</FormLabel>
                    {/* Start Date Picker */}
                    <Input
                        placeholder="Start date:"
                        size="md"
                        type="date"
                        onChange={handleStartDateChange}
                    />
                </FormControl>

                {/* Icon in the center */}

                <FormControl>
                    <FormLabel>End date:</FormLabel>
                    {/* End Date Picker */}
                    <Input
                        placeholder="End date:"
                        size="md"
                        type="date"
                        onChange={handleEndDateChange}
                    />
                </FormControl>
            </Stack>
            {validationError && <Text color="red" mt={2} fontSize='sm'>*{validationError}</Text>}
        </Box>
    );
}
